import axios from "axios";
import { FRESHDESK_API_KEY } from "../../../config/constants";
import { SupportFormValues } from "../../../types";

export const createTicket = async (values: SupportFormValues) => {
  return axios.post("https://autobi.freshdesk.com/api/v2/tickets", values, {
    headers: {
      "Authorization": window.btoa(FRESHDESK_API_KEY),
    },
  });
};
