import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Masonry from "@mui/lab/Masonry";
import Paper from "@mui/material/Paper";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AccordionStyled } from "./styled";
import { ReactComponent as ExpandMoreIcon } from "../../../assets/svg/chevronDown.svg";
import { SxPropsObject } from "../../../types";
import { FAQ_ITEMS } from "../../../config/faq";
import Button from "@mui/material/Button";

const Advantages = () => {
  const [visibleAll, setVisibleAll] = useState<boolean>(false);

  const count = visibleAll ? FAQ_ITEMS.length : 8;

  return (
    <Box id="faq" sx={styles.container}>
      <Container>
        <Typography
          variant="h3"
          color="text.primary"
          align="center"
          mb={5}
          data-aos="flip-up"
        >
          Часті питання
        </Typography>
        <Box sx={styles.content}>
          <Masonry columns={{ xs: 1, md: 2 }} spacing={1}>
            {FAQ_ITEMS.slice(0, count).map((item, index) => (
              <Paper
                sx={styles.item}
                key={index}
                data-aos="fade-up"
                data-aos-delay={200 + index * 100}
                data-aos-duretion={600}
                data-aos-offset={-(index * 60)}
                data-aos-anchor-placement="center"
              >
                <AccordionStyled sx={styles.itemInner}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={styles.itemTitle}>{item.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={styles.itemDetails}>
                    {item.content}
                  </AccordionDetails>
                </AccordionStyled>
              </Paper>
            ))}
          </Masonry>
          <Box sx={styles.actions}>
            <Button
              variant="contained"
              endIcon={
                <ExpandMoreIcon
                  style={{
                    transform: `rotate(${visibleAll ? -180 : 0}deg)`,
                    transition: "all .3s linear",
                  }}
                />
              }
              sx={styles.btn}
              onClick={() => setVisibleAll((state) => !state)}
            >
              Показати {visibleAll ? "менше" : "більше"}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const styles: SxPropsObject = {
  container: (theme) => ({
    py: 15,
    [theme.breakpoints.down(1200)]: {
      py: 10,
    },
  }),
  content: (theme) => ({
    px: 5,
    [theme.breakpoints.down(1200)]: {
      px: 0,
    },
  }),
  item: {
    borderRadius: (theme) => theme.spacing(2),
    bgcolor: "transparent",
  },
  itemInner: {
    borderRadius: (theme) => theme.spacing(2),
    "&:first-of-type, &:last-of-type": {
      borderRadius: (theme) => theme.spacing(2),
    },
  },
  itemTitle: (theme) => ({
    fontSize: "1rem",
    color: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  }),
  itemDetails: (theme) => ({
    fontSize: theme.typography.body2.fontSize,
    color: "#BBB8C7",
    paddingTop: 0,

    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },

    "& ul": {
      margin: "1em 0",
      padding: "0 0 0 1em",
    },
    "& a": {
      color: (theme) => theme.palette.primary.main,
      textDecoration: "underline",

      "&:hover": {
        textDecoration: "none",
      },
    },
  }),
  actions: {
    mt: 4,
    display: "flex",
    justifyContent: "center",
  },
  btn: (theme) => ({
    p: 1,
    borderRadius: 20,
    textTransform: "initial",
    minWidth: 220,

    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      py: 1.25,
      px: 4,
      fontSize: "0.7rem",

      "& .MuiButton-startIcon": {
        width: "1em",
        height: "auto",
      },
    },
  }),
};

export default Advantages;
