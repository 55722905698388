import React, {useState, useEffect, useContext} from 'react';
import {SxPropsObject} from "../../../types";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import {ExpandMore, PanelBox, PanelButton} from './styled';
import Form from '../Form';
import {ReactComponent as FlashIcon} from "../../../assets/svg/flash.svg";
import {ReactComponent as ChevronDownIcon} from "../../../assets/svg/chevronDown.svg";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {AppContext} from "../../../context";

type Props = {
}

const Panel: React.FC<Props> = () => {
  const theme = useTheme();
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [open, setOpen] = useState<boolean>(true);
  const [scroll, setScroll] = useState<number>(0);
  const {setOpenCallback} = useContext(AppContext);

  useEffect(() => {
    //@ts-ignore
    const onScroll = (event) => {
      setScroll(event.target.documentElement.scrollTop);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scroll]);

  const isVisible = scroll >= window.innerHeight;

  return isUpLg ? (
    <PanelBox
      sx={styles.container}
      circular={true}
      visible={isVisible}
    >
      <Box sx={styles.head}>
        <Typography
          sx={styles.title}
          variant="h6"
          component="h6"
          color="text.primary"
        >
          <FlashIcon/> Залишити заявку
        </Typography>
        <ExpandMore
          expand={open}
          onClick={() => setOpen((state) => !state)}
          aria-expanded={open}
          aria-label="Open form"
        >
          <ChevronDownIcon/>
        </ExpandMore>
      </Box>
      <Collapse in={open} timeout="auto" >
        <Form variant="secondary"/>
      </Collapse>
    </PanelBox>
  ) : (
    <PanelButton
      onClick={() => setOpenCallback(true)}
      sx={styles.button}
      visible={isVisible}
    >
      <FlashIcon/>
    </PanelButton>
  );
};

const styles: SxPropsObject = {
  container: {
    transition: 'all .2s linear',
  },
  button: {},
  head: {
    display: 'flex',
  },
  title: {
    '& > svg': {
      display: 'inline-flex',
      marginRight: 1,
      verticalAlign: "middle",
    }
  },
}

export default Panel;

