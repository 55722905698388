import { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { ReactComponent as FlashIcon } from "../../../assets/svg/flash.svg";
import { HeroBg, HeroBox } from "./styled";
import typographyOverlay from "../../../assets/svg/heroTextOverlay.svg";
import { SxPropsObject } from "../../../types";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { AppContext } from "../../../context";

const Hero = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { setOpenCallback } = useContext(AppContext);

  return (
    <HeroBox sx={styles.container}>
      <HeroBg />
      <Container sx={{ position: "relative", zIndex: 10 }}>
        <Box sx={styles.content}>
          <Box data-aos="zoom-in" data-aos-delay={400}>
            <Typography variant="h2" component="h2" mb={isDownMd ? 3 : 5}>
              Сучасні рішення для моніторингу транспорту
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.primary"
              sx={styles.descriptionText}
            >
              Заощаджуйте ваші гроші та час, замовляйте оренду GPS-трекерів.
              Контроль переміщення та підвищення ефективності використання
              вашого транспорту. Швидка автоматизація бізнес-процесів.
            </Typography>
            <Typography
              variant="h6"
              component="span"
              color="text.secondary"
              sx={styles.subtitleDecorated}
            >
              Економія до 100$ в місяць на одиниці техніки.
            </Typography>
          </Box>
          <Box sx={styles.actions} data-aos="zoom-out" data-aos-delay={400}>
            <Button
              variant="contained"
              startIcon={<FlashIcon />}
              sx={styles.actionsButton}
              onClick={() => setOpenCallback(true)}
            >
              Залишити заявку
            </Button>
          </Box>
        </Box>
      </Container>
    </HeroBox>
  );
};

const styles: SxPropsObject = {
  container: () => ({ py: 10 }),
  content: () => ({
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "column",
  }),
  descriptionText: (theme) => ({
    width: "800px",
    margin: "0 auto",

    [theme.breakpoints.down("md")]: {
      width: "500px",
    },

    [theme.breakpoints.down("sm")]: {
      width: "320px",
    },
  }),
  subtitle: () => ({}),
  subtitleDecorated: () => ({
    position: "relative",

    "&::after": {
      content: '""',
      position: "absolute",
      width: "17.5em",
      height: "1.25em",
      top: "100%",
      left: 0,
      backgroundImage: `url(${typographyOverlay})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      pointerEvents: "none",
      zIndex: 2,
    },
  }),
  actions: (theme) => ({
    pt: 10,
    [theme.breakpoints.down("md")]: {
      pt: 8,
    },
  }),
  actionsButton: (theme) => ({
    p: 3,
    borderRadius: 20,
    textTransform: "initial",
    minWidth: 286,

    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      py: 1.75,
      px: 4,
    },
  }),
};

export default Hero;
