import { styled } from '@mui/material/styles';
import Accordion from "@mui/material/Accordion";

export const AccordionStyled = styled(Accordion)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(2),

  '&.Mui-expanded': {
    boxShadow: `inset 4px 0px 0px #275CDE`
  },

  '& .MuiAccordionSummary-content': {
    '&, &.Mui-expanded': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
}));
