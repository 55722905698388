import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { SxPropsObject } from "../../types";
import { Circle } from "../../styled";

const Terms = () => {
  return (
    <>
      <Circle sx={styles.circleFirst} />
      <Circle sx={styles.circleSecond} />
      <Box sx={styles.content}>
        <Box sx={styles.description}>
          <Typography sx={styles.title} variant="h2" color="text.primary">
            Правила користування Програмою
          </Typography>
          <Typography
            sx={styles.subTitle}
            variant="h6"
            color="text.secondary"
            component="div"
          >
            Умови використання Програми Редакція від 04.06.2021
          </Typography>

          <Typography sx={styles.text} variant="body2" component="p">
            Увага! Перед переглядом цього сайту уважно прочитайте ці умови. Якщо
            ви не згодні з цими умовами, не використовуйте цю Програму. Цими
            Умовами використання (у подальшому – «Умови») визначаються правила
            використання Програми. Умови використання мають зобов'язальну силу і
            є невід'ємною частиною угоди, на підставі якої Клієнт отримав право
            використання Програми від AutoBI (у подальшому – «угода»). Клієнт та
            користувачі мають право використовувати Програму лише у випадку
            ознайомлення та повної і беззастережної згоди з цими Умовами. Клієнт
            має право надавати користувачу доступ до Програми виключно у разі,
            якщо Клієнт пересвідчився у тому, що користувач ознайомився і
            погодився з цими Умовами. Клієнт несе повну відповідальність за дії
            користувача.
          </Typography>

          <Typography
            sx={styles.subTitle}
            variant="h6"
            color="text.secondary"
            component="div"
          >
            Опис
          </Typography>

          <Typography sx={styles.text} variant="body2" component="p">
            AutoBI - Смарт простір Вашого автопарку. Пропонує онлайн інструмент
            для ефективного управління парком техніки, обліку запчастин та
            агрегатів, моніторингу штрафів, страхових полісів тощо. Програмна
            продукція AutoBI надає інструмент для ведення обліку та аналізу
            даних для прийняття рішень з управління автопарком.
          </Typography>

          <Typography
            sx={styles.subTitle}
            variant="h6"
            color="text.secondary"
            component="div"
          >
            Облікові записи та інші дані клієнтів та користувачів, реєстрація
          </Typography>

          <Typography sx={styles.text} variant="body2" component="p">
            Під час реєстрації для отримання доступу до Програми, AutoBI вимагає
            надання Клієнтом ПІБ. номер телефону, електронну адресу, назву
            компанії посаду представника Клієнта / користувача у вказаній
            компанії, кількість техніки, що є у автопарку Клієнта. Будь-ласка,
            уважно ознайомтесь з нашими Умовами конфіденційності, у яких
            детально описано, які дані ми збираємо, як зберігаємо та
            використовуємо персональні дані Клієнта, хто може мати ці дані та
            юридичні підстави для такої обробки даних. Після реєстрації AutoBI
            надає Клієнту дані для авторизації у системі. Клієнт зобов'язаний
            надати достовірну і повну запитувану у формі реєстрації інформацію
            про себе і користувачів, та підтримувати цю інформацію в актуальному
            стані. AutoBI має право в будь-який момент вимагати від Клієнта
            надання додаткової інформації та документів на підтвердження
            вищевказаної інформації. У випадку надання Клієнтом недостовірної
            або неповної інформації, ненадання актуальної інформації, ненадання
            документів на підтвердження інформації, AutoBI має право на свій
            розсуд заблокувати або видалити обліковий запис відповідного
            користувача, а також припинити, зупинити або обмежити доступ до
            Програми та її використання Клієнтом.
          </Typography>

          <Typography
            sx={styles.subTitle}
            variant="h6"
            color="text.secondary"
            component="div"
          >
            Використання Програми
          </Typography>

          <Typography sx={styles.text} variant="body2" component="p">
            Використовуючи Програму, Клієнт отримує ліцензію лише для особистого
            використання. Клієнт не можете продати Програму, ділитись ліцензією
            з будь-ким іншим, намагатись скопіювати Програму або спробувати
            заробити на ній кошти без прямого письмового дозволу AutoBI. Якщо
            AutoBI пропонує послугу користування системою безкоштовно, Клієнт
            так само повинен дотримуватись цих положень та не копіювати чи іншим
            чином не використовувати Програму у спосіб, заборонений цим
            розділом. Клієнт не отримує права власності під час придбання
            Програми, але купує (або, у разі отримання безкоштовного доступу)
            ліцензію на використання Програми AutoBI. Використовуючи Програму
            AutoBI Клієнт бере на себе відповідальність за використання програми
            Клієнтом та користувачами Клієнта. Клієнт несе відповідальність за
            використання Програми, здійснене за допомогою облікового запису
            Клієнта та користувачів Клієнта.
          </Typography>

          <Typography
            sx={styles.subTitle}
            variant="h6"
            color="text.secondary"
            component="div"
          >
            Гарантії, застереження і відповідальність
          </Typography>

          <Typography sx={styles.text} variant="body2" component="p">
            Матеріали та послуги системи надаються «як є» без жодних гарантій.
            Програмою AutoBI в будь-який час без повідомлення може вносити зміни
            в матеріали та послуги, що надаються, а також у згадані в них
            послуги, продукти та ціни. AutoBI за жодних обставин не несе
            відповідальності за будь-які збитки (включно з, але не обмежуючись
            збитком від втрати прибутку, даних або від переривання ділової
            активності), що виникли внаслідок використання, неможливості
            використання або результатів використання цього Програми. AutoBI
            залишає за собою право припинити чи обмежувати доступ до Програми чи
            будь-якої її частини. AutoBI гарантує, що він наділений усіма
            правами на надання Клієнту права на використання Програми за Угодою.
            Клієнт зобов'язаний відшкодувати AutoBI усі збитки, завдані
            порушення ним та/або користувачами цих Умов та Угоди. У будь-якому
            випадку розмір відповідальності AutoBI перед Клієнтом не може
            перевищувати оплаченої Клієнтом вартості надання права використання
            Програми.
          </Typography>

          <Typography
            sx={styles.subTitle}
            variant="h6"
            color="text.secondary"
            component="div"
          >
            Використання персональних даних
          </Typography>

          <Typography sx={styles.text} variant="body2" component="p">
            Програма AutoBI використовує різні технології для збирання та
            зберігання інформації, коли Клієнт відвідує сайт. Це може включати в
            себе запис одного або декількох кукі або анонімних ідентифікаторів.
            AutoBI також використовує кукі й анонімні ідентифікатори, коли
            Клієнт взаємодіє з послугами, запропонованими партнерами AutoBI,
            як-от рекламні послуги, наприклад, що можуть з'явитися на інших
            сайтах. Докладніше тут: https://autobi.io/privacy/
          </Typography>

          <Typography
            sx={styles.subTitle}
            variant="h6"
            color="text.secondary"
            component="div"
          >
            Плата за використання сервісу
          </Typography>

          <Typography sx={styles.text} variant="body2" component="p">
            Плата за використання Сервісу справляється шляхом оплати рахунку
            Клієнтом. У випадку, якщо оплата Сервісу не відбулася вчасно, то
            Сервіс залишає за собою право повністю або частково обмежити доступ
            такого Клієнта до Програми.
          </Typography>

          <Typography
            sx={styles.subTitle}
            variant="h6"
            color="text.secondary"
            component="div"
          >
            Права інтелектуальної власності
          </Typography>

          <Typography sx={styles.text} variant="body2" component="p">
            Програма є твором і захищена законодавством України про авторське
            право. За Угодою Програма не передається Клієнту ні у володіння, ні
            у розпорядження, ні у власність. Клієнт не має права передавати,
            продавати або у інший спосіб відчужувати Програму або передавати
            третім особам права на неї (крім випадків, прямо передбачених
            Угодою). Усі права інтелектуальної власності на Програму
            зберігаються. Дизайн Програми, а також створені AutoBI тексти,
            сценарії, графіка, функції, логотипи є власністю або ліцензією
            компанії LTD “AUTOBI“, що підлягають захисту як об'єкти права
            інтелектуальної власності. Клієнт погоджується не брати участь у
            використанні, копіюванні чи розповсюдженні нічого, що міститься на
            сайті чи в Програмі без прямого письмового дозволу AutoBI
            Відповідальність за порушення прав інтелектуальної власності настає
            у відповідності з чинним законодавством України.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const styles: SxPropsObject = {
  circleFirst: () => ({
    left: "100%",
    transform: "translateX(-50%)",
    background: "#330A3A",
  }),
  circleSecond: () => ({
    top: "100%",
    left: 0,
    transform: "translate(-50%, -50%)",
    background: "#081536",
  }),
  content: {
    position: "relative",
    zIndex: 100,
  },
  title: (theme) => ({
    textAlign: "left",
    fontSize: "3rem",
    fontWeight: 700,
    mb: 6,
    [theme.breakpoints.down("md")]: {
      mb: 2,
    },
  }),
  subTitle: (theme) => ({
    pt: 4,
    [theme.breakpoints.down("md")]: {
      pt: 2,
    },
  }),
  text: (theme) => ({
    fontSize: "1rem",
    color: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      br: {
        display: "none",
      },
    },
  }),
  description: () => ({
    marginTop: "0px",
  }),
};

export default Terms;
