import { ReactComponent as Location } from "../../../assets/svg/location.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/svg/phone.svg";
import { ReactComponent as EmailIcon } from "../../../assets/svg/email.svg";
import { Box, Typography } from "@mui/material";
import { SxPropsObject } from "../../../types";

const iconProps = { color: "#8597C6", width: "20px", height: "20px" };

const config = [
  {
    label: "office@auto-bi.io",
    icon: <EmailIcon {...iconProps} />,
  },
  { label: "+38 (096) 725 65 49", icon: <PhoneIcon {...iconProps} /> },
  {
    label: "04119, м. Київ, вул. Дегтярівська, 25/1",
    icon: <Location {...iconProps} />,
  },
  {
    label: "79034, м. Львів, вул. Кам'янецька, 33",
    icon: <Location {...iconProps} />,
  },
];

const SupportContacts = () => {
  return (
    <Box>
      {config.map((item) => (
        <Box sx={styles.contactItem}>
          {item.icon}{" "}
          <Typography variant="subtitle1" ml={1.25}>
            {item.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const styles: SxPropsObject = {
  contactItem: () => ({
    display: "flex",
    alignItems: "center",
    mt: 2,
  }),
};

export default SupportContacts;
