export const PROPOSAL_EXPIRED_MILLISECONDS = 24 * 60 * 60 * 1000;
export const PROPOSAL_TIME =
  new Date().getTime() + PROPOSAL_EXPIRED_MILLISECONDS; // expired after 24h

export const SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";

export const SEND_PULSE_BASE_URL = "https://api.sendpulse.com";
export const SEND_PULSE_CLIENT_ID = "4bc79d018715b10876d8b17bb6e0abd7";
export const SEND_PULSE_CLIENT_SECRET = "1c801fe25fd66d0f148f7b1b6d09da59";
export const SEND_PULSE_SENDER_NAME = "Alarius";
export const SEND_PULSE_SENDER_EMAIL = "admin@alarius.com.ua";
export const CRM_CREATE_ORDER_ENDPOINT =
  "https://crm2203199.asteril.com/api/v2/orders/create/simple/5yntq8o1pzszv8ijrp40gbso499dqgjkr0qprpl6z4";
export const FRESHDESK_API_KEY = "46NQybszIN1RmPUVguAo";
