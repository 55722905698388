// @ts-nocheck

const AgreementTemplate = () => {
  return (
    <div style={{ color: "#fff" }}>
      <p
        className="western"
        align="left"
        style={{
          textIndent: "0.25in",
          marginBottom: "0in",
        }}
      >
        <font>
          <b>Договір</b>
        </font>
      </p>
      <p
        className="western"
        align="left"
        style={{
          textIndent: "0.25in",
          marginBottom: "0in",
        }}
      >
        <font>
          <b>про надання доступу до Програми "AutoBI"</b>
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.25in",
          marginBottom: "0in",
        }}
      >
        <font>
          <b>м. Київ, Україна</b>
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
          border: "none",
          padding: "0in",
        }}
      >
        <br />
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
        }}
      >
        <font>
          <b>Товариство з обмеженою відповідальністю "АЛАРІУС ФМС"</b>
        </font>
        <font>
          , юридична особа належним чином створена, зареєстрована та діюча
          згідно з чинним законодавством України, з кодом ЄДРПОУ 43572863 та
          місцезнаходженням 04119, місто Київ, вул. Дегтярівська, будинок 25/1,
          поверх 3, в особі директора Тараса НІКОЛЕНКО, який діє на підставі
          Статуту (іменоване надалі "Оператор"),
        </font>
      </p>
      <p className="western" align="justify" style={{ marginBottom: "0in" }}>
        <font>
          цим пропонує фізичним особам, юридичним особам та фізичним особам –
          суб'єктам підприємницької діяльності, кожен з яких окремо надалі
          іменується "Користувач", укласти Договір про надання доступу до
          онлайн-сервісу та користування програмною продукцією "AutoBI" в
          порядку і на умовах, передбачених цим Договором.
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
        }}
      >
        <font>
          Відповідно до статті 634 Цивільного кодексу України, цей Договір може
          бути укладений шляхом приєднання Користувача до нього в цілому, шляхом
          надання відповідної заяви про приєднання, яка доступна за адресою{" "}
        </font>
        <font>
          <a href="/agreement#statement" target="_blank" rel="noreferrer">
            <font color="#0563c1">
              <font>
                <u>Заява про приєднання</u>
              </font>
            </font>
          </a>
        </font>
        <font>. Користувач не може запропонувати свої умови Договору.</font>
      </p>
      <p
        className="western"
        align="left"
        style={{
          textIndent: "0.25in",
          marginBottom: "0in",
          textAlign: "center",
        }}
      >
        <font color="#fff">
          <font>
            <b>ТЕРМІНИ І ВИЗНАЧЕННЯ:</b>
          </font>
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
        }}
      >
        <font color="#fff">
          <font>
            <b>"Програма"</b>
          </font>
        </font>
        <font color="#fff">
          <font>
            – програмна продукція "AutoBI", що є результатом комп'ютерного
            програмування у вигляді онлайн-сервісу (як в цілому, так і його
            компонентів), розміщеного на зовнішніх серверах, та програмні засоби
            доступу до неї.
          </font>
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
        }}
      >
        <font color="#fff">
          <font>
            <b>"Користувач"</b>
          </font>
        </font>
        <font color="#fff">
          <font>
            – юридична або фізична особа, якій надано доступ до Програми на
            умовах цього Договору.
          </font>
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
        }}
      >
        <font color="#fff">
          <font>
            <b>"Тариф"</b>
          </font>
        </font>
        <font color="#fff">
          <font>
            – тип Програми, що відрізняється від інших обсягом функціональних
            можливостей, строком використання та вартістю.
          </font>
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
        }}
      >
        <font color="#fff">
          <font>
            <b>"Сайт"</b>
          </font>
        </font>
        <font color="#fff">
          <font>
            – веб-сайт AutoBI, розташований в мережі Інтернет за адресою{" "}
          </font>
        </font>
        <a href="https://auto-bi.io/" target="_blank" rel="noreferrer">
          <font color="#0563c1">
            <font>
              <u>https://auto-bi.io/</u>
            </font>
          </font>
        </a>
        <font color="#fff">
          <font>.</font>
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
        }}
      >
        <font color="#fff">
          <font>
            <b>"Обліковий запис"</b>
          </font>
        </font>
        <font color="#fff">
          <font>
            – обліковий запис Користувача на Сайті, необхідний для його
            аутентифікації і надання доступу до його Особистого кабінету.
          </font>
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
        }}
      >
        <font color="#fff">
          <font>
            <b>"Особистий кабінет Користувача"</b>
          </font>
        </font>
        <font color="#fff">
          <font>
            – розділ Сайту з обмеженим доступом, за допомогою якого Користувач,
            пройшовши процедуру авторизації шляхом введення логіну і паролю, має
            можливість користуватися Програмою.
          </font>
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
        }}
      >
        <font color="#fff">
          <font>
            Інші поняття, використані у цій Угоді, вживаються у розумінні,
            визначеному у Умовах використання та законодавстві України.
          </font>
        </font>
      </p>
      <ol style={{ listStyleType: "none", padding: 0 }}>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            1. ПРЕДМЕТ ДОГОВОРУ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              1.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              В порядку та на умовах, передбачених цим
                              Договором, Оператор надає Користувачеві послуги з
                              доступу до Програми, а Користувач, в свою чергу,
                              оплачує такі послуги в порядку та в розмірах,
                              визначених цим Договором.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              1.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Користувач цим підтверджує та гарантує, що
                              результати послуг, наданих згідно цього Договору,
                              будуть використані виключно для цілей його
                              законної господарської діяльності.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                  fontWeight: "normal",
                }}
              >
                <font>
                  <font size={2} style={{ fontSize: "11pt" }}>
                    <span style={{ fontStyle: "normal" }}>
                      <span
                        style={{
                          width: "60px",
                          display: "inline-block",
                        }}
                      >
                        1.3.
                      </span>
                      <span style={{ background: "transparent" }}>
                        Правовласником програмного забезпечення (особою, якій
                        належать виключні майнові права інтелектуальної
                        власності на Програму) залишається компанія AutoBI LTD.
                      </span>
                    </span>
                  </font>
                </font>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              1.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Всі положення цього Договору поширюються як на
                              Програму "AutoBI" в цілому, так і на її окремі
                              компоненти, які не можуть бути розділені та/або
                              використовуватися на різних комп'ютерах.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            2. ПРАВА ТА ОБОВ'ЯЗКИ СТОРІН
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              2.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Оператор зобов'язується:
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
              <ol style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.1.1.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Надати Користувачеві послуги з доступу до
                                  програми згідно Правил користування Програмою,
                                  розміщених на{" "}
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span style={{ fontWeight: "normal" }}>
                                  <a
                                    href="/terms"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <font color="#0563c1">
                                      <font>
                                        <u>Правила користування</u>
                                      </font>
                                    </font>
                                  </a>
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span style={{ fontWeight: "normal" }}>
                                  <span style={{ background: "transparent" }}>
                                    , та обраного Користувачем Тарифу.
                                  </span>
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.1.2.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Підтримувати працездатність Програми згідно
                                  Правил користування Програмою.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.1.3.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Надавати технічну підтримку Користувачеві
                                  шляхом надання роз'яснень у відповідях на
                                  електронні листи та телефоном.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              2.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Оператор має право:
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
              <ol style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.2.1.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  В будь-який час вносити зміни в Правила
                                  користування Програмою, повідомивши
                                  Користувача про такі зміни шляхом направлення
                                  відповідного повідомлення через Програму під
                                  час або після входу в Особистий кабінет.
                                  Користувач вважається належним чином
                                  повідомленим про зміни в Правилах з моменту
                                  відправлення йому відповідного повідомлення.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.2.2.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Оператор має право змінювати вартість Тарифу,
                                  попередивши Користувача про таку зміни за 30
                                  (тридцять) днів до моменту набрання чинності
                                  новим Тарифом шляхом направлення відповідного
                                  повідомлення через Програму під час або після
                                  входу в Особистий кабінет. Користувач
                                  вважається належним чином повідомленим про
                                  зміни в Тарифах з моменту відправлення йому
                                  відповідного повідомлення.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.2.3.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Оператор має право у будь-який момент в
                                  односторонньому порядку зупинити або обмежити
                                  доступ до Програми та її використання
                                  Користувачем у випадку, якщо Оператор має
                                  обґрунтовані підозри у несанкціонованому
                                  доступі до Програми третіх осіб через
                                  Особистий кабінет Користувача.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.2.4.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  У разі порушення Користувачем умов Договору
                                  та/або Правил, Оператор має право у будь-який
                                  час в односторонньому порядку розірвати даний
                                  Договір, повідомивши про це Користувача через
                                  його Особистий кабінет або електронну пошту,
                                  вказану у Особистому кабінеті, за 1 (один)
                                  робочий день до передбачуваної дати такого
                                  розірвання, без наступних виплат Користувачу
                                  будь-яких компенсацій, пов'язаних із
                                  зазначеним розірванням. Сплачена Користувачем
                                  вартість наданої для використання Програми в
                                  такому випадку не підлягає поверненню.
                                </span>
                                \
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              2.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Користувач зобов'язується:
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
              <ol style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.3.1.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Дотримуватися Правил користування програмою.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.3.2.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Вносити оплату за надані послуги в порядку, в
                                  строки та в розмірах, визначених цим Договором
                                  та обраним Тарифом.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              2.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Користувач має право:
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
              <ol style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.4.1.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  За погодженням з Оператором Користувач має
                                  право змінити Тариф протягом строку дії цього
                                  Договору шляхом подання нової Заяви про
                                  приєднання.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  2.4.2.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Користувач має право розірвати даний Договір
                                  шляхом письмового повідомлення про це
                                  Оператора на його контактну адресу. Сплачена
                                  Користувачем вартість наданої для використання
                                  Програми не підлягає поверненню у цьому разі.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            3. ЦІНА ДОГОВОРУ ТА ПОРЯДОК РОЗРАХУНКІВ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Вартість послуг що надаються за цим Договором
                              встановлена відповідним Тарифом із запропонованих
                              в Додатку А до цього Договору в залежності від
                              обсягу послуг і визначається Заявою про
                              приєднання.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Користувач оплачує послуги з доступу до Програми
                              на підставі рахунків, виставлених Оператором.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Користувач сплачує відповідний рахунок протягом 3
                              (трьох) днів з дати його виставлення.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Обов'язок Користувача з оплати послуг з доступу до
                              Програми вважається виконаним після зарахування
                              грошових коштів на розрахунковий рахунок
                              Оператора.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.5.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Розрахунки між Сторонами за цим Договором
                              здійснюються в безготівковому порядку, шляхом
                              перерахування Сторонами грошових коштів на
                              поточний рахунок іншої Сторони, вказаний в цьому
                              Договорі.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.6.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Всі банківські витрати та комісії в банку
                              Користувача та банках-кореспондентах Користувача
                              мають бути віднесені на рахунок Користувача. Всі
                              банківські витрати та комісії в банку Оператора та
                              банках-кореспондентах Оператора мають бути
                              віднесені на рахунок Оператора.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.7.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Всі кошти, отримані від Користувача в період дії
                              цього Договору, Оператор зараховує в рахунок
                              оплати вартості доступу до Програми у
                              хронологічному порядку, починаючи з раніше
                              виставлених рахунків.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.8.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Оператор не є платником ПДВ. Операції з постачання
                              програмної продукції звільнені від оподаткування
                              податком на додану вартість у відповідності із
                              п.26-1 Підрозділу 2 розділу XX Податкового кодексу
                              України.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            4. КОНФІДЕНЦІЙНІСТЬ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              4.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Сторони розглядатимуть як конфіденційну будь-яку
                              інформацію, що надається їм в межах виконання
                              зобов’язань за цим Договором, у тому числі й таку,
                              що не захищається законодавством, і яка, як їх
                              проінформовано, є конфіденційною або може мати
                              такий характер, і вони не будуть використовувати
                              подібну інформацію для своєї власної користі або
                              користі будь-якої іншої особи або розкривати
                              будь-яку подібну інформацію будь-якій третій
                              особі, за винятком своїх працівників і професійних
                              експертів, від яких обґрунтовано потребується, щоб
                              вони дотримувалися тих самих зобов’язань щодо
                              конфіденційності, які наведені в цьому пункті.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            5. ГАРАНТІЇ СТОРІН
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              5.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Оператор гарантує, що Програма "AutoBI" є
                              власністю ТОВ "АЛАРІУС ФМС", під арештом,
                              заставою, податковою заставою чи іншою забороною
                              не перебуває, судового спору щодо комп’ютерної
                              програми, а також прав щодо неї, в тому числі
                              речових, чи претензій з боку третіх осіб на неї
                              немає.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              5.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Сторони погодили, що право на використання
                              Програми "AutoBI", яке надається Користувачу
                              передається за принципом "як є", тобто Користувачу
                              постачається готовий продукт без прийняття
                              претензій у майбутньому.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            6. ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Сторони за цим Договором звільняються від
                              відповідальності за повне або часткове невиконання
                              своїх зобов'язань у випадку, якщо таке невиконання
                              стало наслідком обставин непереборної сили
                              (форс-мажору). До таких подій належать: стихійні
                              лиха, військові дії, <br />
                              ддос-атаки, дії хакерів та хакерських спільнот,
                              відмовою центру обробки даних, відмова послуги
                              відправки і прийому коротких повідомлень (SMS),
                              прийняття державними органами або органами
                              місцевого самоврядування нормативних або
                              правозастосовних актів та інші дії, які Сторони не
                              могли передбачити на момент укладення Договору
                              та/або негативні наслідки яких неможливо
                              відвернути наявними у Сторін засобами.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              При настанні обставин, зазначених у пункті 6.1.
                              цього Договору, кожна Сторона повинна не пізніше
                              одного робочого дня з моменту настання таких
                              обставин повідомити про них письмово іншу Сторону.
                              Повідомлення має містити дані про характер
                              обставин, оцінку їх впливу на можливість виконання
                              Стороною своїх зобов'язань за цим Договором, а
                              також передбачені терміни їх дії. Обставини
                              форс-мажору повинні бути підтверджені довідкою
                              компетентного органу.{" "}
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              У разі настання обставин, передбачених пунктом
                              6.1. цього Договору, термін виконання Стороною
                              зобов'язань за цим Договором відсувається
                              відповідно до часу, протягом якого діють ці
                              обставини і їх наслідки.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Якщо дія обставин непереборної сили триває понад
                              десять днів, Сторони проводять додаткові
                              переговори для виявлення прийнятних альтернативних
                              способів виконання цього Договору.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            7. АНТИКОРУПЦІЙНЕ ЗАСТЕРЕЖЕННЯ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              7.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Сторони визнають та підтверджують, що вони
                              проводять політику повної нетерпимості до діянь,
                              предметом яких є неправомірна вигода, в тому числі
                              до корупції, яка передбачає повну заборону
                              неправомірних вигід та здійснення виплат за
                              сприяння або спрощення формальностей у зв’язку з
                              господарською діяльністю, забезпечення більш
                              швидкого вирішення тих чи інших питань. Сторони
                              керуються у своїй діяльності застосовним
                              законодавством і розробленими на його основі
                              політикою та процедурами, спрямованими на боротьбу
                              з діяннями, предметом яких є неправомірна вигода,
                              і корупція зокрема.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              7.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Сторони гарантують, що їм самим та їхнім
                              працівникам заборонено пропонувати, давати або
                              обіцяти надати будь-яку неправомірну вигоду
                              (грошові кошти, цінні подарунки тощо) будь-яким
                              особам (включаючи, серед іншого, службовим особам,
                              уповноваженим особам юридичних осіб, державним
                              службовцям), а також вимагати отримання, приймати
                              або погоджуватися прийняти від будь-якої особи,
                              прямо чи опосередковано, будь-яку неправомірну
                              вигоду (грошові кошти, цінні подарунки тощо).
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            8. ПОРЯДОК ВИРІШЕННЯ СПОРІВ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              8.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              У разі виникнення спорів або розбіжностей між
                              Сторонами при виконанні цього Договору або у
                              зв'язку з ним, Сторони зобов'язуються вирішувати
                              їх шляхом переговорів.{" "}
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              8.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              У випадку, якщо Сторони не досягнуть згоди шляхом
                              переговорів, спір передається на розгляд у
                              відповідний суд, відповідно до встановленої
                              підвідомчості та підсудності згідно з чинним
                              законодавством України.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            9. ВІДПОВІДАЛЬНІСТЬ СТОРІН
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              9.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Сторони несуть відповідальність за невиконання та
                              / або неналежне виконання зобов'язань за цим
                              Договором відповідно до чинного законодавства
                              України та цього Договору.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              9.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              У разі порушення Користувачем строків розрахунків,
                              передбачених цим Договором, останній сплачує
                              Оператору пеню в розмірі подвійної облікової
                              ставки НБУ, яка діяла у період прострочення, від
                              суми заборгованості, за кожен день такого
                              прострочення.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              9.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Всі штрафні санкції, передбачені цим Договором,
                              нараховуються за весь період прострочення, але
                              тільки за умови подання Стороною-кредитором
                              Стороні-боржнику відповідної вимоги.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            10. ДІЯ ДОГОВОРУ. ІНШІ УМОВИ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Цей Договір набуває чинності з моменту його
                              підписання обома Сторонами і діє протягом 24
                              (двадцяти чотирьох) місяців.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Оператор має право передавати права та обов’язки
                              за цим Договором третім особам без згоди
                              Користувача. Користувач не має права передавати
                              права та обов’язки за цим Договором третім особам
                              без згоди Оператора.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Зміни до цього Договору, які не стосуються Правил
                              користування Програмою, вносяться шляхом
                              опублікування Оператором нової редакції Договору
                              на сайті
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
                <font>offer.alarius.com.ua</font>
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ background: "transparent" }}>
                              . Оператор повідомляє Користувача про нову
                              редакцію Договору шляхом направлення відповідного
                              повідомлення через Програму під час або після
                              входу в Особистий кабінет. Користувач вважається
                              належним чином повідомленим про зміни в Договорі з
                              моменту відправлення йому відповідного
                              повідомлення
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              У випадку неприйняття Користувачем змін до Правил
                              користування Програмою, дія Договору припиняється
                              протягом 5 (п’яти) днів з моменту повідомлення
                              Користувача про зміну Правил користування
                              Програмою.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.5.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Приєднанням до цього Договору Користувач дає згоду
                              на збирання, реєстрацію, накопичення, зберігання,
                              адаптацію, зміну, поновлення, використання і
                              поширення (розповсюдження, реалізацію, передачу),
                              знеособлення, публікацію, знищення своїх
                              персональних даних, в тому числі з використанням
                              інформаційних (автоматизованих) систем відповідно
                              до умов цього Договору та Закону України "Про
                              захист персональних даних".
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.6.
                            </span>
                            <span style={{ background: "transparent" }}>
                              З питань, не врегульованих цим договором, Сторони
                              керуються чинним законодавством України.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.7.
                            </span>
                            <span style={{ background: "transparent" }}>
                              У разі зміни юридичних адрес та / або
                              розрахункових реквізитів Сторін, Сторона, чиї
                              реквізити змінилися, зобов'язана повідомити про це
                              іншу Сторону протягом 5 (п'яти) робочих днів з
                              моменту вступу в силу таких змін.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.8.
                            </span>
                            <span style={{ background: "transparent" }}>
                              При розірванні або іншому припиненні дії цього
                              Договору, Користувач втрачає право доступу до
                              Програми, а Особистий кабінет та дані облікового
                              запису зберігаються протягом 12 місяців.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p
        className="western"
        align="justify"
        style={{
          fontVariant: "normal",
          fontStyle: "normal",
          fontWeight: "normal",

          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          textDecoration: "none",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <br />
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>
                        ОПЕРАТОР:
                      </span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>
                        Товариство з обмеженою відповідальністю "АЛАРІУС ФМС"
                      </span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>
                        Код ЄДРПОУ:
                      </span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        43572863
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>ІПН</span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        : 265020079987
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>
                        Місцезнаходження:
                      </span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        04119, місто Київ, вул. Дегтярівська, будинок 25/1,
                        поверх 3
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>Р/р:</span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        UA333052990000026008006815625,
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>Банк</span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        : ПАТ «ПРИВАТБАНК», м. Київ,
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>МФО</span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        : 300711{" "}
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          fontVariant: "normal",
          fontStyle: "normal",
          fontWeight: "normal",

          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          textDecoration: "none",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <br />
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>
                        Директор
                      </span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        {" "}
                        Тарас НІКОЛЕНКО
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <br />
      </p>
    </div>
  );
};

export default AgreementTemplate;
