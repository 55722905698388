import { ReactComponent as YoutubeIcon } from "../../../assets/svg/youtube.svg";
import { ReactComponent as LinkedIn } from "../../../assets/svg/linkedin.svg";
import { ReactComponent as TelegramIcon } from "../../../assets/svg/telegram.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/svg/facebook.svg";
import { Box } from "@mui/material";
import { SxPropsObject } from "../../../types";

const iconProps = { color: "#8597C6", width: "32px", height: "32px" };

const config = [
  {
    href: "https://t.me/autobiukraine",
    icon: <TelegramIcon {...iconProps} />,
  },
  {
    href: "https://www.youtube.com/channel/UCiASv97IR0yWw_JSNKBb4FA",
    icon: <YoutubeIcon {...iconProps} />,
  },
  {
    href: "https://www.facebook.com/alarius.ukraine/",
    icon: <FacebookIcon {...iconProps} />,
  },
  {
    href: "https://www.linkedin.com/company/auto-bi/",
    icon: <LinkedIn {...iconProps} />,
  },
];

const SupportLinks = () => {
  return (
    <Box sx={styles.links}>
      {config.map((item) => (
        <Box sx={styles.linkItem}>
          <a href={item.href} style={{ display: "block" }} target="_blank" rel="noreferrer">
            {item.icon}
          </a>
        </Box>
      ))}
    </Box>
  );
};

const styles: SxPropsObject = {
  links: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mt: 5.2,
  },
  linkItem: () => ({
    mr: 4,
  }),
};

export default SupportLinks;
