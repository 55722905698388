import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import bg from '../../../assets/svg/heroBg.svg';
import bgMd from '../../../assets/svg/heroBgMobile.svg';
import overlay from '../../../assets/svg/heroBgOverlay.svg';

export const HeroBox = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  display: 'flex',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    minHeight: '82vh',
  },
}));


export const HeroBg = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url(${bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  pointerEvents: 'none',
  zIndex: 1,

  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${bgMd})`,
    height: '122%',
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${overlay})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    pointerEvents: 'none',
    zIndex: 2,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(12, 11, 16, 0) 88.24%, rgba(12, 11, 16, 1) 100%)',
    pointerEvents: 'none',
    zIndex: 4,
  }
}));


