import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

export const ArrowBox = styled(Box)(({theme}) => {
  return {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    fontSize: '2em',
    height: '1em',
    width: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all .3s linear',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
});
