import bagMoneyIcon from '../../../assets/svg/bagMoney.svg';
import bagIcon from '../../../assets/svg/bag.svg';
import walletIcon from '../../../assets/svg/wallet.svg';
import calendarIcon from '../../../assets/svg/calendar.svg';
import serviceIcon from '../../../assets/svg/service.svg';
import fullDayIcon from '../../../assets/svg/fullDay.svg';
import piggyBankIcon from '../../../assets/svg/piggyBank.svg';
import fuelStationIcon from '../../../assets/svg/fuelStation.svg';
import networkIcon from '../../../assets/svg/network.svg';

export const ADVANTAGES_ITEMS = [
  {
    icon: bagMoneyIcon,
    content: 'Не обов\'язково купувати дорогі трекери, отримай якісне обладнання на умовах оренди',
  },
  {
    icon: bagIcon,
    content: 'Автоматизує бізнес-процеси, для запобігання людських похибок та невірних рішень',
  },
  {
    icon: walletIcon,
    content: 'Система автоматично розраховує витрати та прибуток в розрізі кожного авто та рейсу',
  },
  {
    icon: calendarIcon,
    content: 'Автоматичний контроль терміну придатності документів',
  },
  {
    icon: serviceIcon,
    content: 'Прогнозує виникнення ризиків технічного стану техніки та обладнання',
  },
  {
    icon: fullDayIcon,
    content: 'Моніторинг та технічна підтримка 24/7, у тому числі і за кордоном',
  },
  {
    icon: piggyBankIcon,
    content: 'Оперативне прийняття рішень дозволить заощадити до $100 для кожної одиниці техніки на основі зведеної інформації про:',
    items: [
      'Ефективність роботи техніки та водіїв',
      'ТО та ремонти',
      'Паливо'
    ]
  },
  {
    icon: fuelStationIcon,
    content: 'Аналітична панель витрат палива надасть інформацію про точний розхід та переліміт використання палива в зручному форматі з розрахунком вартості рейсу',
  },
  {
    icon: networkIcon,
    content: 'Сервери Alarius розміщені у Німеччині та Британії, будь впевнений в конфіденційності та безпеці даних місцезнаходження твого транспорту',
  },
];
