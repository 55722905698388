import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PROPOSAL_TIME } from "./config/constants";
//@ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./routes/Home";
import NotFound from "./routes/NotFound";
import { CssBaseline, ThemeProvider } from "@mui/material";

import theme from "./config/theme";
import DefaultLayout from "./layout/DefaulLayout";
import Terms from "./routes/Terms";
import Agreement from "./routes/Agreement";
import Support from "./routes/Support";

function App() {
  console.log('Build 2.4');

  useEffect(() => {
    const expiringTime = localStorage.getItem("proposalExpiredTime");
    if (!expiringTime) {
      localStorage.setItem("proposalExpiredTime", String(PROPOSAL_TIME));
    }
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/" element={<DefaultLayout />}>
            <Route path="agreement" element={<Agreement />} />
            <Route path="terms" element={<Terms />} />
            <Route path="support" element={<Support />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
