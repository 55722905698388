import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { Links, Logo, Nav, NavItem, NavLink } from "./styled";
import { ReactComponent as LogoImage } from "../../assets/svg/logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/menu.svg";
//@ts-ignore
import { scroller } from "react-scroll";
import { SxPropsObject } from "../../types";
import { Link, useLocation, useNavigate } from "react-router-dom";

const MENU_ITEMS = [
  {
    title: "Переваги",
    uri: "advantages",
  },
  {
    title: "Як розпочати?",
    uri: "usage",
  },
  {
    title: "Відгуки",
    uri: "reviews",
  },
  {
    title: "Про нас",
    uri: "about",
  },
  {
    title: "Часті питання",
    uri: "faq",
  },
];

const Header = () => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = (name: string): void => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: name } });

      return;
    }

    scroller.scrollTo(name, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  useEffect(() => {
    const scrollTo = location?.state?.scrollTo;

    if (scrollTo) {
      scroller.scrollTo(scrollTo, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [location?.state?.scrollTo]);

  return (
    <>
      <AppBar
        position="absolute"
        color={isDownMd ? "primary" : "transparent"}
        elevation={0}
        data-aos="fade-down"
        data-aos-delay={0}
        data-aos-duration={200}
      >
        <Container>
          <Toolbar sx={styles.toolbar}>
            <Logo>
              <Link to="/">
                <LogoImage />
              </Link>
            </Logo>
            {isUpMd && (
              <>
                <Nav>
                  {MENU_ITEMS.map((item, index) => {
                    return (
                      <NavItem
                        key={index}
                        data-aos="fade-up"
                        data-aos-delay={200 + index * 100}
                      >
                        <NavLink
                          variant="body1"
                          color="text.primary"
                          type="button"
                          onClick={() => handleScroll(item.uri)}
                        >
                          {item.title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
                <Links>
                  <NavLink
                    color="text.secondary"
                    variant="body1"
                    href="tel:+380957256549"
                  >
                    +38 (095) 725-65-49
                  </NavLink>
                </Links>
              </>
            )}
            {isDownMd && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen((state) => !state)}
                edge="start"
                sx={{ paddingRight: 0 }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {isDownMd && (
        <Drawer
          open={open}
          anchor="right"
          onClose={() => setOpen(false)}
          sx={styles.drawer}
          PaperProps={{
            sx: styles.drawerPaper,
          }}
        >
          <List>
            {MENU_ITEMS.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  onClick={() => {
                    setOpen(false);
                    handleScroll(item.uri);
                  }}
                >
                  <ListItemText
                    primary={item.title}
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "0.875rem",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem disablePadding>
              <ListItemButton>
                <NavLink
                  color="text.secondary"
                  variant="body1"
                  href="tel:+380957256549"
                >
                  +38 (095) 725-65-49
                </NavLink>
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      )}
    </>
  );
};

const styles: SxPropsObject = {
  toolbar: (theme) => ({
    py: 5,
    px: 0,
    justifyContent: "space-between",

    [theme.breakpoints.down(1200)]: {
      py: 2,
      px: 0,
    },
    [theme.breakpoints.down("md")]: {
      minHeight: 42,
      py: 1,
      px: 0,
    },
  }),
  drawer: {
    width: 300,
  },
  drawerPaper: {
    width: "50vw",
    maxWidth: 280,
    bgcolor: "common.black",
    backgroundImage: "unset",
  },
};

export default Header;
