import { ThemeOptions } from "@mui/material/styles/createTheme";
import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme();

const appThemeBreakPoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1368,
  xl: 1600,
};

export const appThemeConfig: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#275CDE",
    },
    secondary: {
      main: "#FFD800",
    },
    background: {
      default: "#0C0B10",
      paper: "#211F2A",
    },
    text: {
      secondary: "#FFD800",
    },
    common: {
      black: "#0C0B10",
    },
  },
  breakpoints: {
    values: appThemeBreakPoints,
  },
  typography: {
    fontFamily: [
      '"Nunito Sans"',
      "-apple-system",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "sans-serif",
    ].join(","),
    h2: {
      fontSize: "3rem",
      fontWeight: 700,
      [defaultTheme.breakpoints.down(1200)]: {
        fontSize: "2.3125rem",
      },
      [defaultTheme.breakpoints.down(appThemeBreakPoints.md)]: {
        fontSize: "1.25rem",
      },
      [defaultTheme.breakpoints.down(appThemeBreakPoints.sm)]: {
        fontSize: "1.5rem",
      },
    },
    h3: {
      fontSize: "2.5rem",
      fontWeight: 600,
      [defaultTheme.breakpoints.down(1200)]: {
        fontSize: "2rem",
      },
      [defaultTheme.breakpoints.down(appThemeBreakPoints.md)]: {
        fontSize: "1.25rem",
      },
      [defaultTheme.breakpoints.down(appThemeBreakPoints.sm)]: {
        fontSize: "1.5rem",
      },
    },
    h4: {
      fontSize: "1.75rem",
      fontWeight: 600,
      [defaultTheme.breakpoints.down(1200)]: {
        fontSize: "1.25rem",
      },
    },
    h6: {
      [defaultTheme.breakpoints.down(appThemeBreakPoints.md)]: {
        fontSize: "0.875rem",
      },
    },
    body1: {
      fontSize: "1.125rem",
      [defaultTheme.breakpoints.down(appThemeBreakPoints.md)]: {
        fontSize: "0.8125rem",
      },
    },
    body2: {
      [defaultTheme.breakpoints.down(appThemeBreakPoints.md)]: {
        fontSize: "0.75rem",
      },
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#fff",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          [defaultTheme.breakpoints.down(appThemeBreakPoints.md)]: {
            backgroundColor: "#0C0B10",
          },
        },
      },
    },
  },
};

const theme = createTheme(appThemeConfig);

export default theme;
