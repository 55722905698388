import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";
import Box, {BoxProps} from "@mui/material/Box";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const ExpandMore = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'expand',
})<ExpandMoreProps>(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface PanelBoxProps extends BoxProps {
  circular: boolean;
  visible: boolean;
}

export const PanelBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'circular' && prop !== 'visible',
})<PanelBoxProps>(({ theme, visible }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
  borderTopLeftRadius:  theme.spacing(3),
  borderTopRightRadius:  theme.spacing(3),
  position: 'fixed',
  right: theme.spacing(2),
  bottom: 0,
  zIndex: 9999,
  background: theme.palette.primary.main,
  boxShadow: '0 0 64px rgba(255, 255, 255, 0.15)',
  maxWidth: theme.spacing(50),
  width: '100%',
  transform: `translate(0, ${visible ? 0 : '100%'})`,
  opacity: visible ? 1 : 0,

  [theme.breakpoints.down('xl')]: {
    maxWidth: theme.spacing(46),
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  }

}));

interface PanelButtonProps extends IconButtonProps {
  visible: boolean;
}

export const PanelButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'visible',
})<PanelButtonProps>(({ theme, visible }) => ({
  position: 'fixed',
  width: theme.spacing(8),
  height: theme.spacing(8),
  bottom: theme.spacing(8),
  right: theme.spacing(2),
  zIndex: 9999,
  background: theme.palette.primary.main,
  border: '1px solid rgba(255, 255, 255, 0.36)',
  transform: `translate(0, ${visible ? 0 : '100%'})`,
  opacity: visible ? 1 : 0,
}));
