import React from 'react';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import {Grid, useTheme} from '@mui/material';
import {TIMELINE_ITEMS} from "./constants";
import {ReactComponent as PointerIcon} from "../../../../assets/svg/pointer.svg";
import {ReactComponent as CurveImage} from "../../../../assets/svg/timelineCurve.svg";
import {TimeLineItemBox, TimeLineItemBoxElement} from "./styled";
import {SxPropsObject} from "../../../../types";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";

type TimeLineItemProps = {
  date: string,
  title: string,
  reverse: boolean,
  first: boolean,
};

const TimeLineItem: React.FC<TimeLineItemProps> = (props) => {
  const {date, title, reverse, first} = props;

  return (
    <TimeLineItemBox reverse={reverse}>
      <TimeLineItemBoxElement
        reverse={reverse}
        first={first}
      />
      <Typography
        variant="body2"
        color="text.primary"
        component="div"
        sx={styles.itemTitle}
      >
        {title}
      </Typography>
      <Typography
        variant="h6"
        color="text.secondary"
        component="div"
        sx={styles.itemDate}
      >
        {date}
      </Typography>
    </TimeLineItemBox>
  )
}

const TimeLine = () => {
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={styles.container}>
      {isUpMd ? (
        <>
          <Box sx={{mr: 10, py: 2, zIndex: 2}}>
            <Grid container alignItems="end">
              {TIMELINE_ITEMS.map((el, index) => {
                if(index % 2 === 0) {
                  return (
                    <Grid item xs={3} key={index}>
                      <Box data-aos="fade-left" data-aos-delay={200 + index * 100}>
                        <TimeLineItem first={index === 0} reverse={false} {...el}/>
                      </Box>
                    </Grid>
                  )
                }
                return null;
              })}
            </Grid>
          </Box>
          <Box sx={styles.baseline}>
            <PointerIcon style={{
              position: 'absolute',
              left: '100%',
              top: '50%',
              transform: 'translate(-2.5em, -50%) rotate(45deg)',
              width: '1.25em',
              height: '1.25em',
              color: '#FFD800',
              zIndex: 2,
            }}/>
            <CurveImage style={{
              position: 'absolute',
              left: '100%',
              top: '90%',
              transform: 'translate(-6%, -100%)',
              width: '41.1%',
              height: 'auto',
            }}/>
          </Box>
          <Box sx={{ml: 10, py: 2, zIndex: 2}}>
            <Grid container alignItems="start">
              {TIMELINE_ITEMS.map((el, index) => {
                if(index % 2 !== 0) {
                  return (
                    <Grid item xs={3} key={index}>
                      <Box data-aos="fade-left" data-aos-delay={200 + index * 100}>
                        <TimeLineItem first={false} reverse={true} {...el}/>
                      </Box>
                    </Grid>
                  )
                }
                return null;
              })}
            </Grid>
          </Box>
        </>
      ) : (
        <Grid spacing={2} container alignItems="start">
          {TIMELINE_ITEMS.map((el, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Box>
                  <TimeLineItem first={false} reverse={true} {...el}/>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Box>
  );
};

const styles: SxPropsObject = {
  container: (theme) => ({
    pt: 8,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      pt: 0,
    }
  }),
  baseline: () => ({
    position: 'relative',
    m: 0,
    width: '90%',
    borderRadius: 0.5,
    height: 4,
    background: (theme) => theme.palette.primary.main,
  }),
  itemTitle: {},
  itemDate: (theme) => ({
    [theme.breakpoints.down(1200)]: {
      fontSize: '0.875rem',
    }
  })
}

export default TimeLine;
