import React from 'react';
import Typography from "@mui/material/Typography";
import { blueGrey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import {ADVANTAGES_ITEMS} from "./constants";
import {Circle} from "../../../styled";
import {SxPropsObject} from "../../../types";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";

const Advantages = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box id="advantages" sx={styles.container}>
      <Circle sx={styles.circle}/>
      <Container sx={{position: 'relative', zIndex: 6}}>
        <Typography
          variant="h3"
          color="text.primary"
          mb={5}
          sx={{textAlign: 'center'}}
          data-aos="flip-up"
        >
          Ключові переваги
        </Typography>
        <Box sx={styles.content}>
          <Grid container spacing={isDownMd ? 2 : 6} justifyContent="center">
            {ADVANTAGES_ITEMS.map((item, index) => (
              <Grid item key={index} xs={index + 1 > 6 ? 12 : 6} sm={6} md={4}>
                <Card
                  sx={styles.card}
                  data-aos="fade-left"
                  data-aos-delay={200 + index * 100}
                >
                  <Box sx={styles.cardIcon}>
                    <CardMedia
                      component="img"
                      image={item.icon}
                      alt=""
                    />
                  </Box>
                  <CardContent sx={styles.cardContent}>
                    <Typography variant="body2" component="p" color="text.primary" sx={{fontSize: '1rem'}}>
                      {item.content}
                    </Typography>
                    {item.items && (
                      <Box sx={styles.cardItems}>
                        {item.items.map((el, index) => {
                          return (
                            <Box sx={styles.cardItem} key={index}>
                              <Typography
                                variant="body2"
                                component="span"
                                color={blueGrey[100]}
                              >
                                {el}
                              </Typography>
                            </Box>
                          )
                        })}
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: () => ({
    py: 15,
    position: 'relative'
  }),
  circle: {
    left: '100%',
    top: '100%',
    transform: 'translate(-50%, -50%)',
    background: '#330A3A'
  },
  content: theme => ({
    px: 3,

    [theme.breakpoints.down('md')]: {
      px: 0,
    }
  }),
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
  },
  cardIcon: {
    mb: 1,
    fontSize: '1.5em',
    width: '1em',
    height: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardContent: () => ({
    flexGrow: 1,
    p: 0,

    '&:last-child': {
      pb: 0
    }
  }),
  cardItems: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    mt: 2,
  }),
  cardItem: () => ({
    display: 'flex',
    alignItems: 'flex-start',

    '&::before': {
      content: '""',
      flexShrink: 0,
      width: '0.42em',
      height: '0.42em',
      mr: '0.42em',
      position: 'relative',
      top: '0.34em',
      borderRadius: '50%',
      border: (theme) => `1px solid ${theme.palette.secondary.main}`,
    }
  }),
}

export default Advantages;
