import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { ProposalBgBox, ProposalBox } from "./styled";
import Typography from "@mui/material/Typography";
import typographyOverlay from "../../../assets/svg/proposalTextOverlay.svg";
import Timer from "../../Timer";
import { SxPropsObject } from "../../../types";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import Form from "./Form";

const Proposal = () => {
  const theme = useTheme();
  const isUpSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <ProposalBox>
      {isUpSm && (
        <ProposalBgBox>
          <Box data-aos="zoom-out" data-aos-duration={1200} />
        </ProposalBgBox>
      )}
      <Container sx={{ zIndex: 10 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Box data-aos="fade-up" data-aos-duration={600}>
              <Timer />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={styles.content} data-aos="slide-left">
              <Typography
                variant="h3"
                component="h3"
                color="text.primary"
                sx={styles.heading}
              >
                Поради{" "}
                <Typography
                  variant="h3"
                  component="span"
                  color="text.secondary"
                  sx={styles.headingDecorated}
                >
                  Як правильно обрати
                </Typography>
                <br /> водія на роботу?
              </Typography>
              <Typography variant="body2" color="text.primary" component="p">
                Заповнюйте форму заявки та отримайте безкоштовний чекліст.
                Інформація зібрана в чеклісті, грунтується на досвіді HR
                фахівців транспортної галузі. Тут ти знайдеш рекомендації щодо
                правильного вибору та оцінки досвіду професійних водіїв.
              </Typography>
              <Form />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ProposalBox>
  );
};

const styles: SxPropsObject = {
  content: (theme) => ({
    py: 6,
    pl: 15,
    minHeight: 720,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.down(1200)]: {
      py: 6,
      pl: 6,
    },
    [theme.breakpoints.down("md")]: {
      py: 2,
      pl: 2,
      minHeight: 540,
    },
    [theme.breakpoints.down("sm")]: {
      py: 10,
      pl: 0,
    },
  }),
  heading: (theme) => ({
    mb: 1,
    [theme.breakpoints.down("md")]: {
      mb: 3,
    },
  }),
  headingDecorated: () => ({
    position: "relative",
    display: "inline-block",
    mb: "0.2em",

    "&::after": {
      content: '""',
      position: "absolute",
      width: "10em",
      height: "0.425em",
      top: "100%",
      left: 0,
      backgroundImage: `url(${typographyOverlay})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      pointerEvents: "none",
      zIndex: 2,
    },
  }),
};

export default Proposal;
