import React from "react";

export const FAQ_ITEMS = [
  {
    title: 'З чого складається комплексна пропозиція?',
    content: 'Комплексна пропозиція від Alarius включає GPS трекер та інше обладнання, що можна взяти в оренду або купити, а також підключення до сучасної системи моніторингу та управління з технічною підтримкою з будь яких питань в режимі 24/7.',
  },
  {
    title: 'Які умови абонплати та вартість використання?',
    content: 'Вартість  оренди GPS трекера та абонплати за користування системою моніторинга та управління транспортом можна отримати залишивши заявку на нашему сайті',
  },
  {
    title: 'Як обрати потрібне мені GPS обладнання?',
    content: 'Заповніть форму заявки на сайті і наш спеціаліст зв\'яжеться з вами та допоможе обрати рішення, що підходить саме вам.',
  },
  {
    title: 'Як замовити та отримати GPS обладнання?',
    content: (
      <>
        4 прості кроки
        <ul>
          <li>Залиш контактні дані для консультації та формування вартості</li>
          <li>Отримай обладнання та доступи до системи</li>
          <li>Скануй QR код активації трекеру та підключи до авто.</li>
          <li>Готово! Поринь у світ управління автопарку наступного покоління</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Як купити?',
    content: 'Після підтвердження замовлення, отримуєш рахунок на оплату, який можна оплати будь-яким зручним способом. ',
  },
  {
    title: 'Як підключити GPS обладнання до авто?',
    content: 'За рахунок удосконалення GPS обладнання, наш GPS трекер  просто встановити на авто самостійно: після ознайомлення з покроковою інструкцією, процес встановлення відбувається швидко та просто. Турбуючись про зручність наших клієнтів, додатково ми пропонуємо послуги з встановлення будь-якого GPS - обладнання. ',
  },
  {
    title: 'Як і де я буду переглядати інформацію про мій автопарк?',
    content: 'Після оплати замовлення отримуєш GPS обладнання, яке дуже легко встановити самостійно, а після сканування QR-кода дуже просто отримати доступ до  системи моніторингу та управління транспортом, і вже з першого дня твоя техніка під контролем. ',
  },
  {
    title: 'Як можна ознайомитися з функціоналом системи моніторингу та управління транспортом?',
    content: (
      <>
        AutoBI - це онлайн система управління автопарком, яка зчитує дані з трекерів, та дозволяє швидко та легко отримати інформацію щодо місцезнаходження вашого транспорту в режимі онлайн. Система дозволяє контролювати розхід палива, планувати технічний огляд авто, та отримувати сповіщення про перевищення швидкісного режиму водієм. Детальний відео огляд
        {' '} <a href="https://www.youtube.com/watch?v=297tZCNQpDU" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=297tZCNQpDU</a>
      </>
    )
  },
  {
    title: 'Чи можу я отримати демо доступ?',
    content: 'Для отримання демо доступу з демо даними для детального ознайомлення з системою моніторингу та управління транспортом можна залишивши заявку; демо доступ з демо даними надається безкоштовно з трайл періодом 7 днів.'
  },
  {
    title: 'Що отримує моя компанія в разі початку співпраці?',
    content: 'Ти отримуєш інструмент, який економить твій  час за рахунок автоматизації  бізнес-процесів та  запобігання людських похибок та невірних рішень.'
  },
  {
    title: 'Скільки насправді коштів я зможу зекономити?',
    content: (
      <ul>
        <li>Не обов'язково купувати дорогі трекери, отримай якісне обладнання на умовах оренди</li>
        <li>Оперативне прийняття рішень дозволить заощадити до 100$ для кожної одиниці техніки на основі зведеної інформації про: Паливо , ТО та ремонти, Ефективність роботи техніки та водіїв.</li>
      </ul>
    )
  },
  {
    title: 'Як я можу контролювати витрати палива?',
    content: 'Аналітична панель надасть інформацію про точний розхід та переліміт використання палива в зручному форматі з розрахунком вартості рейсу'
  },
  {
    title: 'Чи потрібно щось завантажувати та встановлювати?',
    content: 'Розпочати користуватись системою AutoBi дуже просто. Для цього вам потрібно завантажити мобільний додаток чи скористатись онлайн версію програми.'
  },
  {
    title: 'Чи є обмеження по кількості транспорту та користувачів?',
    content: 'Кількість користувачем необмежена, а кількість техніки залежить від розміру абонплати. '
  },
  {
    title: 'Чи зможу я контролювати свій автопарк з мобільного телефону?\n',
    content: 'Доступ до твого автопарку з будь якого пристрою та через мобільний додаток дає змогу з будь-якого місця управляти автопарком'
  },
  {
    title: 'Чи буду я отримувати інформацію в онлайн режимі? ',
    content: 'Так, дані моніторингу передаються в режимі онлайн з часовим інтервалом 1 секунда, а оновлення інформації у системі відбувається у реальному режимі.'
  },
  {
    title: 'Чи буде працювати система в інших країнах?',
    content: 'Ми пропонуємо моніторинг та технічну підтримку 24/7, у тому числі і за кордоном. Дані з GPS обладнання передаються в онлайн режимі з будь-якої точки Европи, а система працює на будь-якому пристрої будь де. '
  },
  {
    title: 'Наскільки захищені мої дані?',
    content: 'Сервери AutoBI розміщені у Німеччині та Британії, будь впевнений в конфіденційності та безпеці даних місцезнаходження твого транспорту'
  },
  {
    title: 'Гарантія та умови повернення\n',
    content: 'Гарантія на обладнання - 24 місяці від моменту встановлення; GPS трекер та GPS обладнання  повертається у тому ж стані у якому було отримано з урахуванням періоду користування  '
  },
  {
    title: 'Що зробити якщо я не знайшов відповіді на всі свої запитання?\n',
    content: 'Заповнити форму для зворотнього  зв\'язку, наш спеціаліст зв\'яжеться та відповість на всі ваші питання '
  },
];
