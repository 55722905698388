import { Box, Typography } from "@mui/material";
import SupportForm from "../../domains/support/SupportForm";
import { SxPropsObject } from "../../types";
import SupportContacts from "../../domains/support/SupportContacts";
import SupportLinks from "../../domains/support/SupportLinks";

const Support = () => {
  return (
    <>
      <Typography sx={styles.title} variant="h2" color="text.primary">
        Технічна підтримка
      </Typography>
      <Box sx={styles.content}>
        <Box sx={styles.supportForm}>
          <Typography sx={styles.subTitle} variant="h6" color="text.secondary">
            Заявка до служби підтримки
          </Typography>
          <SupportForm variant="primary" />
        </Box>
        <Box sx={styles.contacts}>
          <Typography sx={styles.subTitle} variant="h6" color="text.secondary">
            Зв'яжіться з нами
          </Typography>

          <SupportContacts />
          <SupportLinks />
        </Box>
      </Box>
    </>
  );
};

const styles: SxPropsObject = {
  content: (theme) => ({
    display: "flex",
    position: "relative",
    zIndex: 100,

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  }),
  title: (theme) => ({
    textAlign: "left",
    fontSize: "3rem",
    fontWeight: 700,
    width: "100%",
    mb: 6,
    [theme.breakpoints.down("md")]: {
      mb: 2,
      textAlign: "center",
    },
  }),
  supportForm: (theme) => ({
    width: "538px",

    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  }),
  subTitle: (theme) => ({
    pt: 4,
    [theme.breakpoints.down("md")]: {
      pt: 2,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }),
  contacts: (theme) => ({
    marginLeft: "130px",

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      mt: "40px",
    },
  }),
};

export default Support;
