import { Box, Container } from "@mui/material";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Outlet } from "react-router-dom";
import { SxPropsObject } from "../../types";

const DefaultLayout = () => {
  return (
    <Box sx={styles.wrapper}>
      <Header />
      <Box component="main" sx={styles.container}>
        <Container>
          <Box sx={styles.content}>
            <Outlet />
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

const styles: SxPropsObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  container: {
    p: 0,
    overflow: "hidden",
    display: "flex",
    flex: 1,
  },
  content: (theme) => ({
    position: "relative",
    py: theme.spacing(22),
    px: theme.spacing(3),
    [theme.breakpoints.down(1200)]: {
      py: theme.spacing(18),
      px: 0,
    },
    [theme.breakpoints.down("md")]: {
      py: theme.spacing(12),
      px: 0,
    },
  }),
};

export default DefaultLayout;
