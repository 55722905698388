import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';

export const Logo = styled(Box)`
  display: flex;
  align-items: center;
  width: 182px;
  flex-shrink: 0;

  ${({theme}) => theme.breakpoints.down(1200)} {
    width: 136px;
  }

  ${({theme}) => theme.breakpoints.down('md')} {
    width: 86px;
  }
  
  img, svg {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
  }
`;

export const Nav = styled(Box)`
  display: flex;
  align-items: flex-start;
  margin: 0 40px 0 auto;

  ${({theme}) => theme.breakpoints.down(1200)} {
    margin-left: auto;
  }
`;

export const Links = styled(Box)`
  display: flex;
  
  & > a {
    &:not(:first-of-type) {
      margin-left: 40px;
      
      ${({theme}) => theme.breakpoints.down(1200)} {
        margin-left: 16px;
      }
    }
  }
`;

export const NavItem = styled(Box)`
  &:not(:first-of-type) {
    margin-left: 40px;

    ${({theme}) => theme.breakpoints.down(1200)} {
      margin-left: 16px;
    }
  }
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  position: relative;
  cursor: pointer;

  ${({theme}) => theme.breakpoints.down(1200)} {
    font-size: 1rem;
  }

  ${({theme}) => theme.breakpoints.down('md')} {
    font-size: 0.875rem;
  }
  
  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    display: flex;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: currentColor;
    border-radius: 1px;
    opacity: 0;
    transition: opacity .2s ease-in, width .2s ease-in;
  }
  
  &:hover {
    &::after {
      opacity: 1;
      width: 100%;
    }
  }
  
`;

const blue = {
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

export const ButtonMenu = styled(ButtonUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${blue[500]};
  padding: 12px 24px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${blue[700]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
