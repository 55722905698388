import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import bgPrimary from '../../../assets/svg/proposalBg.svg';
import bgSecondary from '../../../assets/img/proposalBgSecondary.png';

export const ProposalBox = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  display: 'flex',
  position: 'relative',
  background: theme.palette.primary.main,

  '&::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${bgPrimary})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    backgroundSize: 'cover',
    pointerEvents: 'none',
    zIndex: 1,
  }
}));

export const ProposalBgBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '50%',
  left: 0,
  height: '100%',
  zIndex: 2,
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },

  '& > div': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${bgSecondary})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
}));
