import React from 'react';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import {SxPropsObject} from "../../../types";

const PARTNERS_ITEMS = [
  {
    name: 'ukravit',
    uri: 'https://ukravit.com.ua/',
    logo: '/assets/img/partners/ukravit.png',
  },
  {
    name: 'agricom',
    uri: 'https://www.agricom.com.ua/',
    logo: '/assets/img/partners/agricom.png',
  },
  {
    name: 'pptrans',
    uri: '',
    logo: '/assets/img/partners/pptrans.png',
  },
  {
    name: 'mksbeton',
    uri: 'https://mks-beton.com.ua/',
    logo: '/assets/img/partners/mksbeton.png',
  },
  {
    name: 'dtek',
    uri: 'https://dtek.com/',
    logo: '/assets/img/partners/dtek.png',
  },
  {
    name: 'transfer',
    uri: 'https://transfer.ck.ua/',
    logo: '/assets/img/partners/transfer.png',
  },
]

const Partners = () => {

  return (
    <Box sx={styles.container}>
      <Container>
        <Typography
          variant="h3"
          color="text.primary"
          sx={styles.heading}
          data-aos="flip-up"
        >
          Нам довіряють
        </Typography>
        <Box sx={styles.content}>
          <Grid container spacing={2} justifyContent="center">
            {PARTNERS_ITEMS.map((item, index) => (
              <Grid item key={index} xs={6} md={4}>
                <Card
                  sx={styles.card}
                  data-aos="fade-zoom-in"
                  data-aos-delay={200 + index * 100}
                >
                  <CardMedia
                    component="img"
                    image={item.logo}
                    sx={styles.cardImage}
                    alt={item.name}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: theme => ({
    pt: 15,
    pb: 30,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      pt: 10,
      pb: 20,
    },
  }),
  content: () => ({}),
  heading: {
    mb: 5,
    textAlign: 'center',
  },
  card: theme => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    height: 120,

    [theme.breakpoints.down('md')]: {
      height: 64,
    },
  }),
  cardImage: theme => ({
    maxWidth: 180,
    maxHeight: 74,

    [theme.breakpoints.down('md')]: {
      maxWidth: 100,
      maxHeight: 36,
    },
  }),
}

export default Partners;
