import { SupportFormValues } from "../../../types";

export const initialValues: SupportFormValues = {
  name: "",
  email: "",
  phone: "",
  description: "",
  status: 2,
  priority: 2,
  subject: "Заявка до служби підтримки",
  tags: ["Alarius_landing"],
  custom_fields: {
    cf_phone_number: '',
    cf_organization: '',
  },
};
