import React, { useRef, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import Container from '@mui/material/Container';
import {REVIEWS_ITEMS} from "./constants";
import Paper from "@mui/material/Paper";
import {ReactComponent as CarIcon} from "../../../assets/svg/car.svg";
import {ReactComponent as ArrowLeftIcon} from "../../../assets/svg/arrowLeft.svg";
import {ReactComponent as ArrowRightIcon} from "../../../assets/svg/arrowRight.svg";
import {Circle} from "../../../styled";
import {ArrowBox} from "./styled";
import {Rating} from "@mui/material";
import {SxPropsObject} from "../../../types";

const Reviews = () => {

  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    //@ts-ignore
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    //@ts-ignore
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Box id="reviews" sx={styles.container}>
      <Circle sx={styles.circleFirst}/>
      <Circle sx={styles.circleSecond}/>
      <Container sx={{zIndex: 10, position: 'relative'}}>
        <Box
          sx={styles.content}
          data-aos="fade-up"
          data-aos-duration={600}
        >
          <ArrowBox onClick={handlePrev} sx={styles.arrowPrev}>
            <ArrowLeftIcon/>
          </ArrowBox>
          <ArrowBox onClick={handleNext} sx={styles.arrowNext}>
            <ArrowRightIcon/>
          </ArrowBox>
          <Swiper
            //@ts-ignore
            ref={sliderRef}
            modules={[Autoplay, Navigation]}
            autoplay={{
              delay: 3600,
              disableOnInteraction: false,
            }}
            speed={600}
            spaceBetween={16}
            loop
            grabCursor
            slidesPerView={1}
          >
            {REVIEWS_ITEMS.map((item, index) => {
              const {rating, content, user, cars} = item;
              return (
                <SwiperSlide key={index}>
                  <Paper sx={styles.card}>
                    <Rating
                      name={`${user.name} review rating is ${rating}`}
                      value={rating}
                      readOnly
                      sx={styles.cardRating}
                    />
                    <Typography variant="body1" color="text.primary" sx={styles.cardContent}>
                      {content}
                    </Typography>
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        {[user.name, user.position].join(', ')}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                        sx={styles.cardQuantity}
                      >
                        <CarIcon/>
                        {cars}{' '}авто у парку
                      </Typography>
                    </Box>
                  </Paper>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};

const styles:SxPropsObject = {
  container: theme => ({
    pt: 30,
    pb: 15,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      pt: 20,
      pb: 10,
    },
  }),
  content: theme => ({
    px: 14,
    [theme.breakpoints.down(1200)]: {
      px: 10,
    },

    [theme.breakpoints.down('md')]: {
      px: 0,
    },
  }),
  arrowPrev: theme => ({
    left: theme.spacing(8),
    [theme.breakpoints.down(1200)]: {
      left: theme.spacing(6),
    },
  }),
  arrowNext: theme => ({
    right: theme.spacing(8),
    [theme.breakpoints.down(1200)]: {
      right: theme.spacing(6),
    },
  }),
  circleFirst: {
    left: '100%',
    transform: 'translateX(-50%)',
    background: '#330A3A'
  },
  circleSecond: {
    top: 0,
    left: 0,
    transform: 'translate(-50%, -30%)',
    background: '#081536'
  },
  card: theme => ({
    p: 5,
    borderRadius: 2,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      background: 'transparent',
      p: 0,
    },
  }),
  cardRating: theme => ({
    mb: 4,
    [theme.breakpoints.down('md')]: {
      mb: 2,
    },
  }),
  cardQuantity: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > svg': {
      height: '1em',
      width: 'auto',
      mr: 1,
      flexShrink: 0,
    }
  }),
  cardContent: theme => ({
    fontSize: '1rem',
    mb: 4,
    maxWidth: 784,
    [theme.breakpoints.down('md')]: {
      mb: 2,
    },
  }),
}

export default Reviews;

