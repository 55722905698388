import { useMemo, useState } from "react";
import Hero from "../../components/sections/Hero";
import Faq from "../../components/sections/Faq";
import Trackers from "../../components/sections/Trackers";
import Advantages from "../../components/sections/Advantages";
import Proposal from "../../components/sections/Proposal";
import Reviews from "../../components/sections/Reviews";
import Partners from "../../components/sections/Partners";
import Usage from "../../components/sections/Usage";
import About from "../../components/sections/About";
import { CallbackPanel, CallbackModal } from "../../components/Callback";
import { AppContext } from "../../context";
//@ts-ignore
import "aos/dist/aos.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { SxPropsObject } from "../../types";
import { Box } from "@mui/material";

const Home = () => {
  const [openCallback, setOpenCallback] = useState<boolean>(false);
  const contextValue = useMemo(
    () => ({
      openCallback,
      setOpenCallback,
    }),
    [openCallback]
  );

  return (
    <AppContext.Provider value={contextValue}>
      <Header />

      <Box component="main" sx={styles.container}>
        <Hero />
        <Trackers />
        <Advantages />
        <Usage />
        <Reviews />
        <Partners />
        <Proposal />
        <About />
        <Faq />
      </Box>

      <Footer />
      <CallbackPanel />
      <CallbackModal />
    </AppContext.Provider>
  );
};

const styles: SxPropsObject = {
  container: {
    p: 0,
    overflow: "hidden",
  },
};

export default Home;
