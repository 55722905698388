import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { EQUIPMENTS_ITEMS, TRACKERS_ITEMS } from "./constants";
import { Circle } from "../../../styled";
import Item from "./Item";
import { SxPropsObject } from "../../../types";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { Autoplay, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as CircleCheckIcon } from "../../../assets/svg/circle_check.svg";
import "swiper/css";
import "swiper/css/scrollbar";
import AdditionTrackingItem from "./AdditionTrackingItem";

// TODO Refactoring  based on theme principles

const Trackers = () => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isUpSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box sx={styles.container}>
      <Circle sx={styles.circleFirst} />
      <Circle sx={styles.circleSecond} />
      <Container sx={{ position: "relative", zIndex: 2 }}>
        <Typography
          sx={styles.title}
          variant="h3"
          color="text.primary"
          data-aos="flip-up"
        >
          Умови оренди GPS-трекера
        </Typography>

        <Box sx={styles.paymentInfo} data-aos="fade-up" data-aos-delay={100}>
          <Typography
            sx={styles.paymentInfoTitle}
            component="h4"
            color="text.primary"
          >
            Вартість місячної абонплати включає:
          </Typography>
          <Box sx={styles.paymentInfoItemWrapper}>
            <Box sx={styles.paymentInfoItem}>
              <CircleCheckIcon />
              <Typography
                sx={styles.paymentInfoDescription}
                variant="subtitle1"
                component="span"
                color="text.primary"
              >
                Доступ до системи моніторингу
              </Typography>
            </Box>
            <Box sx={styles.paymentInfoItem}>
              <CircleCheckIcon />
              <Typography
                sx={styles.paymentInfoDescription}
                variant="subtitle1"
                component="span"
                color="text.primary"
              >
                Оренду GPS-трекера
              </Typography>
            </Box>
            <Box sx={styles.paymentInfoItem}>
              <CircleCheckIcon />
              <Typography
                sx={styles.paymentInfoDescription}
                variant="subtitle1"
                component="span"
                color="text.primary"
              >
                SIM-картка для передачі даних
              </Typography>
            </Box>
            <Box sx={styles.paymentInfoItem}>
              <CircleCheckIcon />
              <Typography
                sx={styles.paymentInfoDescription}
                variant="subtitle1"
                component="span"
                color="text.primary"
              >
                Технічна підтримка 24/7
              </Typography>
            </Box>
            <Box sx={styles.paymentInfoItem}>
              <CircleCheckIcon />
              <Typography
                sx={styles.paymentInfoDescription}
                variant="subtitle1"
                component="span"
                color="text.primary"
              >
                За бажанням ви можете придбати будь-який GPS-трекер у власне
                користування, та оплачувати лише за доступ до системи.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={styles.buyInfo}>
          <Typography sx={styles.buyInfoText} component="p">
            <span style={{ color: "#FFD800" }}>**</span>{" "}
            Гарантія на обладнання — 24 місяці з моменту встановлення. Після
            закінчення терміну оренди GPS-трекер необхідно повернути у технічно
            справному стані.
          </Typography>
        </Box>
        <Box>
          {isUpSm && (
            <Box sx={styles.trackersWrapper}>
              <Grid container spacing={2} justifyContent="center">
                {TRACKERS_ITEMS.map((item, index) => (
                  <Grid item key={item.id + index} xs={12} sm={6} md={4}>
                    <Box data-aos="fade-up" data-aos-delay={index * 100}>
                      <Item entity={item} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {isDownSm && (
            <Box sx={styles.carousel}>
              <Swiper
                modules={[Autoplay, Scrollbar]}
                autoplay={{
                  delay: 3600,
                  disableOnInteraction: false,
                }}
                speed={600}
                spaceBetween={8}
                loop
                grabCursor
                slidesPerView={2}
                scrollbar={{ draggable: true }}
              >
                {TRACKERS_ITEMS.map((item, index) => {
                  return (
                    <SwiperSlide key={`slide-${index}`}>
                      <Box
                        data-aos="fade-up"
                        data-aos-delay={index * 100}
                        sx={styles.slideInner}
                      >
                        <Item entity={item} />
                      </Box>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Box>
          )}
        </Box>
        <Box>
          <Grid container spacing={2} sx={styles.equipmentCards}>
            {EQUIPMENTS_ITEMS.map((item, index) => (
              <Grid item key={item.id} sx={styles.equipmentCard}>
                <Box data-aos="fade-up" data-aos-delay={index * 100}>
                  <AdditionTrackingItem entity={item} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const styles: SxPropsObject = {
  container: (theme) => ({
    py: 15,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      py: 3,
    },
  }),
  circleFirst: () => ({
    left: "100%",
    transform: "translateX(-50%)",
    background: "#330A3A",
  }),
  circleSecond: () => ({
    top: "100%",
    left: 0,
    transform: "translate(-50%, -50%)",
    background: "#081536",
  }),
  title: (theme) => ({
    textAlign: "center",
    mb: 8,
    [theme.breakpoints.down("md")]: {
      mb: 5,
    },
  }),
  trackersWrapper: {
    mt: 12,
  },
  carousel: (theme) => ({
    marginRight: theme.spacing(-4),
    mt: 8,

    "& .swiper-slide": {
      display: "flex",
      flexDirection: "column",
      height: "auto",
    },

    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0.7),
    },
  }),
  slideInner: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  paymentInfo: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.background.paper,
    pt: 4,
    pb: 4,
    pl: 8,
    pr: 8,
    borderRadius: "16px",
    mt: "40px",
  }),
  paymentInfoTitle: (theme) => ({
    fontSize: "1.3rem",
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
  }),
  paymentInfoItemWrapper: (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    mt: "20px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      width: "350px",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      width: "300px",
    },
  }),
  paymentInfoItem: (theme) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mt: 2,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      ml: "10px",
    },
  }),
  paymentInfoDescription: {
    ml: 1,
    display: "flex",
    flex: 1,
  },
  buyInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
  },
  buyInfoText: {
    color: "#909090",
    fontSize: "12px",
  },
  equipmentCards: (theme) => ({
    mt: "60px",

    [theme.breakpoints.down("md")]: {
      mt: "20px",
    },
  }),
  equipmentCard: (theme) => ({
    width: "50%",
    gap: "20px",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }),
};

export default Trackers;
