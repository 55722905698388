export const TRACKERS_ITEMS = [
  {
    id: 1,
    name: "TELTONIKA FMB 020",
    connection: "Роз'єм OBD ||",
    type: null,
    primaryImage: "/assets/img/trackers/tracker1.png",
    secondaryImage: "/assets/img/trackers/tracker1Connection.png",
    description:
      "Надкомпактний пристрій для відстеження переміщення транспорту з підтримкою технологій GNSS, GSM, Bluetooth і роз'ємом OBDII для підключення до автомобіля. Це ідеальний трекер для широкого кола завдань, включаючи керування парком легких комерційних автомобілів, журнал водія, страхову телематику (UBI), оренду автомобілів, лізинг . Трекер підтримує функцію виявлення зіткнень, яка дозволяє користувачеві отримувати повідомлення у разі виникнення автомобільної аварії. Використовуючи дані акселерометра, отримані під час зіткнення, можна візуалізувати кут та виміряти силу, з якою сталося зіткнення з автомобілем. Це може бути корисним у рішеннях для страхової телематики. Пристрій підтримує різні датчики Bluetooth з низьким енергоспоживанням, маячки, гарнітури гучномовця, оновлення прошивки та конфігурації через Bluetooth. Цей пристрій - найкращий вибір для тих, хто шукає практичне рішення з OBD.",
    characteristics: [
      "Точність < 2,5 м",
      "Технологія GSM - Смуги 2G: чотиридіапазонний 850/900/1800/1900 Мгц",
      "Підтримка даних SMS (текст/дані)",
      "Резервний акумулятор 3.7 В 45 мАг",
      "BLUETOOTH – 4.0 + LE. Периферійні пристрої, що підтримуються: датчик температури і вологості,  навушники, сканер штрих-коду Inateck, підтримка універсальних датчиків BLE",
      "Ступінь захисту IP41",
      "Розміри 52,6 х 29,1 х 26 мм (Д х Ш х В)",
    ],
    interfaces: [
      "GNSS антена  - Внутрішня ",
      "GSM антена - Внутрішня",
      "порт 2.0 Micro-USB ",
      "Світлодіодна індикація",
      "SIM-карта: Nano-SIM",
      "Обсяг пам'яті: 128 МБ внутрішньої флеш-пам'яті",
    ],
    features: [
      "Датчики: акселерометр",
      "Сценарії: Екодрайвінг, виявлення перевищення швидкості, виявлення заклинювання, лічильник палива GNSS, виявлення надмірного холостого ходу, виявлення від'єднання, виявлення буксирування, виявлення аварії, автоматичне геозонування, ручна установка геозон, відключення",
      "Контроль палива OBDII dongle",
      "Датчик запалювання: Акселерометр, зовнішня напруга живлення",
    ],
    certificates: ["CE/RED", "UKSA", "EAC", "REACH", "RoHS"],
    note: null,
    usage: null,
    price: 219,
  },
  {
    id: 2,
    name: "TELTONIKA FMP 100",
    connection: "Розетка 12-24 В",
    type: null,
    primaryImage: "/assets/img/trackers/tracker2.png",
    secondaryImage: "/assets/img/trackers/tracker2Connection.png",
    description:
      "GPS-трекер з Bluetooth модулем і роз'ємом для прикурювача (автомобільної розетки) забезпечує швидке підключення, завдяки цьому пристрій дозволяє оперативно отримати доступ до моніторингу транспорту. Підключення до живлення через роз'єм прикурювача не вимагає проведення монтажу в сервісному центрі або СТО. На корпусі трекера вбудована кнопка перемикання між робочим і особистим використанням автомобіля що дозволяє зручно і легко розділяти робочий і особистий пробіг авто.",
    characteristics: [
      "Точність <2,5 м",
      "Технологія GSM - Смуги 2G: чотиридіапазонний 850/900/1800/1900 Мгц",
      "Підтримка даних SMS (текст/дані)",
      "Резервний акумулятор 170 мАч Літій-іонний акумулятор 3,7 В (0,63 Вт·год)",
      "BLUETOOTH – 4.0 + LE. Периферійні пристрої, що підтримуються: Датчик температури і вологості,  навушники, сканер штрих-коду Inateck, підтримка універсальних датчиків BLE",
      "Розміри 96,7x33,4x27,5 мм (Д x Ш x В)",
    ],
    interfaces: [
      "GNSS антена - Внутрішня",
      "GSM антена - Внутрішня\n",
      "порт USB 2.0 Micro-USB для налаштування",
      "порт USB типу A для зарядки зовнішніх пристроїв (5 В, 1 А)",
      "Світлодіодна індикація",
      "SIM-карта - Nano-SIM",
      "Обсяг пам'яті: 128 МБ внутрішньої флеш-пам'яті",
    ],
    features: [
      "Датчики: Акселерометр",
      "Сценарії: Екодрайвінг, виявлення перевищення швидкості, розрахунок витрати палива по GNSS, виявлення надмірного холостого ходу, виявлення від'єднання трекера, виявлення буксирування, виявлення аварії, автоматичне і ручне геозонування, оповіщення про поїздки, режим сну: GPS-сон, глибокий сон онлайн, глибокий сон, надглибокий сон",
      "Віртуальний одометр",
      "Датчик запалювання: Акселерометр, зовнішня напруга живлення",
    ],
    certificates: ["CE/RED", "UKSA", "EAC", "REACH", "RoHS"],
    note: null,
    usage: null,
    price: 229,
  },
  {
    id: 3,
    name: "TELTONIKA FMT 100",
    connection: "Клеми акумулятора",
    type: null,
    primaryImage: "/assets/img/trackers/tracker3.png",
    secondaryImage: "/assets/img/trackers/tracker3Connection.png",
    description:
      "Компактний, водонепроникний GNSS трекер з підтримкою Bluetooth та внутрішньою резервною батареєю. Трекер оснащений спеціальним затискним роз'ємом для швидкого підключення кабелю живлення до автомобільного акумулятора. Міцний водонепроникний корпус, вбудований акселерометр і датчики гіроскопа з надзвичайно точною функцією відстеження зіткнень роблять цей пристрій ідеальним для рішень страхового ринку.",
    characteristics: [
      "Точність < 3 м",
      "Технологія GSM - Смуги 2G: чотиридіапазонний 850/900/1800/1900 Мгц",
      "Підтримка даних SMS (текст/дані)",
      "Резервний акумулятор 170 мАч, Літій-іонний акумулятор 3,7 В (0,63 Вт·год)",
      "BLUETOOTH – 4.0 + LE. Периферійні пристрої, що підтримуються: Датчик температури і вологості, навушники, OBDII dongle, Inateck Сканер штрих-коду, підтримка універсальних датчиків BLE",
      "Ступінь захисту IP65",
      "Розміри: 92,5 х 57,6 х 14 мм (Д х Ш х В)",
      "Вага: 63 г",
    ],
    interfaces: [
      "GNSS антена - Внутрішня",
      "GSM антена - Внутрішня",
      "порт 2.0 Micro-USB ",
      "Світлодіодна індикація",
      "SIM-карта: Micro-SIM",
      "Обсяг пам'яті: 128 МБ внутрішньої флеш-пам'яті",
    ],
    features: [
      "Датчики: Акселерометр та гіроскоп",
      "Сценарії Екодрайвінг, виявлення перевищення швидкості, розрахунок витрати палива по GNSS, виявлення перешкод GSM сигналу, виявлення надмірного холостого ходу, виявлення від'єднання трекера, виявлення буксирування, виявлення аварії, автоматичне і ручне геозонування, оповіщення про поїздки, режим сну: GPS-сон, глибокий сон онлайн, глибокий сон, надглибокий сон",
      "Контроль палива: OBDII dongle",
      "Датчик запалювання: Акселерометр, зовнішня напруга живлення, оберти двигуна (OBDII dongle) ",
    ],
    certificates: ["CE/RED", "UKSA", "EAC", "REACH", "RoHS"],
    note: null,
    usage: null,
    price: 199,
  },
];

export const EQUIPMENTS_ITEMS = [
  {
    id: 1,
    name: "GPS-маяк",
    connection: "Резервний міні-трекер прихованого монтажу",
    type: "Для додаткового контролю",
    primaryImage: "/assets/img/trackers/tracker4.png",
    secondaryImage: "/assets/img/trackers/tracker4Connection.png",
    description: `Для надійності GPS-трекер маяк використовується у парі з основним трекером. Основний трекер виконує функцію контролю переміщення, а трекер ""маяк"" прихованого монтажу забезпечує додатковий захист транспортного засобу у разі відключення передачі даних від головного трекера. Міцний, водонепроникний корпус пристрою (ступінь захисту IP67) та великий вибір монтажних аксесуарів, дозволяє швидко встановити трекер розмістивши його у потайному місці. Енергоефективна батарея забезпечує 1 рік автономної роботи в режимі надсилання запису один раз на день.Також пристрій може використовуватися для відстеження цінних вантажів, інструментів, будівельної техніки, вагонів, контейнерів, підйомників, котушок з дротом, причіпного чи навісного обладнання та інших несамохідних об\`єктів.`,
    characteristics: [
      "Точність < 3 м",
      "Технологія GSM - 2G  чотиридіапазонний GSM 850/900/1800/1900 Мгц",
      "Підтримка даних SMS (текст/дані)",
      "Внутрішні батареї: 2x 2200 мАг Li-SOCl2 батареї з надзвичайно низьким саморазрядом 7,2 В (15,84 Вт·год)",
      "BLUETOOTH – 5.0 + LE. Периферійні пристрої, що підтримуються: датчик температури і вологості, підтримка універсальних датчиків BLE",
      "Ступінь захисту IP68",
      "Розміри 78 x 63 x 28 мм (Д x Ш x В)",
      "Вага: 197 г",
    ],
    interfaces: [
      "GNSS антена  - Внутрішня ",
      "GSM антена - Внутрішня",
      "порт 2.0 Micro-USB ",
      'Кнопка "Скидання" на платі для вимкнення',
      "SIM: Внутрішній слот eSIM",
      "Обсяг пам'яті: 128 МБ внутрішньої флеш-пам'яті (220 000 записів)",
    ],
    features: [
      "Датчики: Акселерометр",
      "Сценарії: відправка записів за розкладом, виявлення нахилу, виявлення перевищення швидкості, виявлення перешкод, автоматичне і ручне геозонування, режим сну: GPS-сон, глибокий сон онлайн, глибокий сон, надглибокий сон",
      "Протоколи: UDP / TCP / SMS. Відправка даних: основний сервер, сервер що дублює і резервний сервер",
    ],
    certificates: ["CE/RED", "EAC", "RoHS", "REACH"],
    note: null,
    usage: [
      "Відстеження переміщення авто, в разі відключення основного трекеру\n",
      "Попередження викрадення авто \n",
      "Повернення викраденного авто \n",
      "Моніторинг будівельного обладнання\n",
      "Відстеження причепів\n",
      "Відстеження цінних вантажів\n",
      "Відстеження морських контейнерів\n",
    ],
  },
  {
    id: 2,
    name: "Відеомоніторинг",
    connection:
      "Подвійна камера, яка одночасно фіксує події на дорозі та в салоні авто",
    type: "Для додаткового контролю",
    primaryImage: "/assets/img/trackers/tracker5.png",
    secondaryImage: "/assets/img/trackers/tracker5Connection.png",
    description: `Рішення підходить для зйомки відео або фото зображень подій, які можуть відбутися в дорозі, наприклад, аварії. Встановлені на лобовому склі автомобіля, дві камери, фронтальна і задня, використовуються для запису відео до і після подій, періодичної фотозйомки або за запитом і відправлення всіх даних на сервер. Крім того рішення забезпечує безпеку пасажирів, оскільки ведеться запис того, що відбувається всередині транспортного засобу. Відео або зображення за всіма сценаріями, такими як еко- водіння, виявлення холостого ходу, виявлення відключення, виявлення буксирування або перетин геозон.
    Налаштування передачі фото/відео: 
    Відео/фото по запитом
    Відео за подією ДТП
    Періодична відправка фото на сервер`,
    characteristics: [
      "Подвійна камера: спереду і ззаду (однакові характеристики)",
      "Кут огляду: по горизонталі 120 °, по вертикалі 70 °, \n Здатність зображення: за замовчуванням 1280 x 720 (період передачі 50 КБ 18 секунд), \n Налаштовуються: 160 x 120; 320 х 240; 640 х 480; 1920 х 1080. \n Роздільна здатність відео: 720P (20 сек. - 6 МБ, період передачі ~ 320 сек.)",
      "Ефект денного і нічного бачення: вдень - кольоровий запис, вночі - чорно-білий",
      "Діапазон вхідної напруги: 9-36 В. Споживання електричного струму в режимі очікування - 60 мА",
      "Зберігання відео: карта MicroSD - 2 шт. (Максимум 64 ГБ до 72 годин відео)",
      "Розміри корпусу камери: 126,2х36,6х36,6 мм (Д x Ш x В)",
    ],
    interfaces: null,
    features: null,
    certificates: ["CE", "FCC", "RoHS"],
    note: "Для встановлення, налаштування та коректної роботи потрібно додатково проконсультуватися з інженером.",
    usage: null,
  },
];
