export const REVIEWS_ITEMS = [
  {
    id: 1,
    rating: 5,
    content: `Обладнання дуже просто встановлюється: самостійно встановила,
      скачала мобільний додаток, і одразу отримала доступ до контролю
      переміщення авто в режимі онлайн; Все дуже швидко та зручно.`,
    user: {
      name: 'Олена',
      position: 'Власник Інтернет-магазину. м. Київ.'
    },
    cars: '5',
  },
  {
    id: 2,
    rating: 5,
    content: `Вразив сучасний дизайн софту, все дуже продумано та інтуїтивно
      зрозуміло, багато чого можна контролювати та вести облік; є модуль
      контролю палива та можливість формування звітів.`,
    user: {
      name: 'Олександр',
      position: 'Приватний підприємець, м. Харків.'
    },
    cars: '2',
  },
  {
    id: 3,
    rating: 5,
    content: `Завдяки встановленню обладнання для GPS моніторингу наша компанія
      почала заощаджувати кошти на паливі, що наразі дуже важливо. Просте
      налаштування геозон у системі дозволило будувати та контролювати маршрути,
      що в результаті зменшило час простою транспорту та період знаходження
      машин у городських заторах.`,
    user: {
      name: 'Дмитро',
      position: 'Керівник служби доставки м. Львів.'
    },
    cars: '20+',
  },
  {
    id: 4,
    rating: 5,
    content: `Добре, що одразу не потрібно витрачати кошти на придбання трекерів,
      для себе ми обрали варіант оренди обладнання, на вибір є різні варіанти 
      приєднання. Спочатку  були сумніви, яле виявилось встановити та підключити 
      дуже просто, ми зробили це самостійно; Система управління транспортом не 
      перевантажена зайвою інформацією: відображається лише те, що потрібно,
      також можна налаштувати інформаційну панель під себе, це дуже зручно. 
      Взагалі я задоволений.`,
    user: {
      name: 'Володимир',
      position: 'Керівник відділу логістики, м.Дніпро.'
    },
    cars: '10+',
  },
  {
    id: 5,
    rating: 5,
    content: `
      Більшість торгівельних представників нашої
      компанії завищували щоденний пробіг за своїм робочим маршрутом,
      часто автомобілі використовувалися в особистих цілях.
      Після встановлення GPS трекерів та підключення системи
      онлайн моніторингу транспорту AutoBi наша компанія 
      заощадила до 20% щомісячних витрат на паливі завдяки 
      відстеженню маршруту пересування машин по місту та контролю пробігу в
      режимі реального часу.`,
    user: {
      name: 'Анастасія',
      position: 'Головний бухгалтер дистриб\'юторської компанії'
    },
    cars: '30+',
  },
];
