import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FilesConfig } from "../../domains/agreement/config";
import File from "../../domains/agreement/File";
import TermsWarningMessage from "../../domains/agreement/TermsWarningMessage";
import { Circle } from "../../styled";
import { SxPropsObject } from "../../types";
import Box from '@mui/material/Box';
//@ts-ignore
import { scroller } from "react-scroll";

const Agreement = () => {
  const location = useLocation();
  const scrollToId = location?.hash?.replace("#", "");

  useEffect(() => {
    if (scrollToId) {
      scroller.scrollTo(scrollToId, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [scrollToId]);

  return (
    <>
      <Circle sx={styles.circleFirst} />
      <Circle sx={styles.circleSecond} />
      <Box sx={styles.content}>
        <Typography sx={styles.title} variant="h2" color="text.primary">
          Правила та умови
        </Typography>

      <TermsWarningMessage />

      {FilesConfig.map((data, index) => (
        <File key={index} {...data} isOpened={scrollToId === data.id} />
      ))}
      </Box>
    </>
  );
};

const styles: SxPropsObject = {
  circleFirst: () => ({
    left: "100%",
    transform: "translateX(-50%)",
    background: "#330A3A",
  }),
  circleSecond: () => ({
    top: "100%",
    left: 0,
    transform: "translate(-50%, -50%)",
    background: "#081536",
  }),
  content: {
    position: 'relative',
    zIndex: 100,
  },
  title: (theme) => ({
    textAlign: "left",
    fontSize: "3rem",
    fontWeight: 700,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      mb: 2,
    },
  }),
};

export default Agreement;
