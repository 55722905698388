import React, { useState } from "react";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import { MessageType, SxPropsObject, SupportFormValues } from "../../../types";
import { Portal, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { FormikHelpers } from "formik/dist/types";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { PatternFormat } from "react-number-format";
import { ReactComponent as PointerIcon } from "../../../assets/svg/pointer.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/svg/phone.svg";
import { ReactComponent as UserIcon } from "../../../assets/svg/user.svg";
import { ReactComponent as EmailIcon } from "../../../assets/svg/email.svg";
import { ReactComponent as ChattingIcon } from "../../../assets/svg/chatting.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/svg/error.svg";
import { useNavigate } from "react-router-dom";
import { schema } from "./schema";
import { createTicket } from "./api";
import { initialValues } from "./config";

type Props = {
  variant: "primary" | "secondary";
};

const SupportForm: React.FC<Props> = (props) => {
  const { variant } = props;

  const [message, setMessage] = useState<MessageType | null>(null);

  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const onSubmit = async (
    values: SupportFormValues,
    helpers: FormikHelpers<SupportFormValues>
  ): Promise<any> => {
    const { setSubmitting, resetForm } = helpers;

    setSubmitting(true);

    try {
      const response = await createTicket({
        ...values,
        custom_fields: {
          ...values.custom_fields,
          cf_phone_number: values.phone,
        },
      });

      if (response.status === 201) {
        setMessage({
          visible: true,
          type: "success",
          content: "Дані успішно відправлено!",
        });
        resetForm();
      }
    } catch {
      setMessage({
        visible: true,
        type: "error",
        content: "Виникла помилка під час відправлення данних!",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => {
          return (
            <Box
              sx={styles.wrapper}
              component="form"
              onSubmit={handleSubmit}
              noValidate
            >
              <TextField
                sx={styles.field}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Електронна адреса"
                name="email"
                size={isUpMd ? "medium" : "small"}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon color="#8597C6" />
                    </InputAdornment>
                  ),
                  endAdornment: Boolean(touched.email && errors.email) && (
                    <InputAdornment position="start">
                      <ErrorIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={styles.field}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                margin="normal"
                required
                fullWidth
                id="name"
                placeholder="Прізвище та ім’я"
                name="name"
                size={isUpMd ? "medium" : "small"}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <UserIcon color="#8597C6" />
                    </InputAdornment>
                  ),
                  endAdornment: Boolean(touched.name && errors.name) && (
                    <InputAdornment position="start">
                      <ErrorIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <PatternFormat
                sx={styles.field}
                customInput={TextField}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                margin="normal"
                required
                fullWidth
                id="phone"
                placeholder="Телефон"
                size={isUpMd ? "medium" : "small"}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon color="#8597C6" />
                    </InputAdornment>
                  ),
                  endAdornment: Boolean(touched.phone && errors.phone) && (
                    <InputAdornment position="start">
                      <ErrorIcon />
                    </InputAdornment>
                  ),
                }}
                value={values.phone}
                name="phone"
                format="+38 (###) ### ## ##"
                allowEmptyFormatting={true}
                mask="_"
                type="tel"
              />

              <TextField
                sx={styles.field}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                margin="normal"
                required
                fullWidth
                id="description"
                placeholder="Опишіть ваше звернення"
                name="description"
                size={isUpMd ? "medium" : "small"}
                autoComplete="off"
                multiline
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ChattingIcon color="#8597C6" />
                    </InputAdornment>
                  ),
                  endAdornment: Boolean(touched.name && errors.name) && (
                    <InputAdornment position="start">
                      <ErrorIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography sx={styles.infoText}>
                Натискаючи на кнопку, ви даєте згоду на обробку персональних
                даних та погоджуєтеся з
                <Typography sx={styles.link} onClick={() => navigate("/terms")}>
                  політикою конфіденційності.
                </Typography>
              </Typography>
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                endIcon={<PointerIcon color="#8597C6" />}
                sx={(theme) => {
                  let style;
                  if (typeof styles.button === "function") {
                    style = styles.button(theme);
                  }
                  return {
                    ...style,
                    background:
                      variant === "primary"
                        ? theme.palette.primary.main
                        : theme.palette.common.black,
                  };
                }}
              >
                Надіслати
              </LoadingButton>
            </Box>
          );
        }}
      </Formik>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={message?.visible}
          autoHideDuration={5000}
          onClose={() => setMessage(null)}
        >
          <Alert
            onClose={() => setMessage(null)}
            severity={message?.type || "info"}
          >
            {message?.content}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

const styles: SxPropsObject = {
  wrapper: (theme) => ({
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
    },
  }),
  field: (theme) => ({
    my: 1,

    "&:last-child": {
      mb: 0,
    },

    "& .MuiOutlinedInput-root": {
      color: "#909090",
      fontSize: "1rem",
      borderRadius: theme.spacing(1),
      background: theme.palette.common.white,
      borderWidth: 1,

      [theme.breakpoints.down("md")]: {
        fontSize: "0.875rem",
      },

      "&.Mui-focused": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.dark,
          borderWidth: 1,
        },
      },

      "& .MuiInputBase-input": {
        height: "1.2em",
      },
    },

    "& .MuiOutlinedInput-input": {
      "&::-webkit-input-placeholder": {
        opacity: 1,
      },

      "&:-webkit-autofill": {
        borderRadius: "inherit",
        caretColor: "#909090",
        WebkitBoxShadow: "0 0 0 100px #fff inset",
        WebkitTextFillColor: "#909090",
      },
    },

    "& .MuiInputAdornment-root": {
      svg: {
        [theme.breakpoints.down("md")]: {
          width: "1rem",
          height: "auto",
        },
      },
    },

    "& .MuiFormHelperText-root": {
      ml: 0,
      mr: 0,
      mt: 0,

      "&.Mui-error": {
        color: theme.palette.text.primary,
      },
    },
  }),
  infoText: () => ({
    fontSize: "0.75rem",
  }),
  button: (theme) => ({
    p: 1.75,
    borderRadius: 20,
    textTransform: "initial",
    background: theme.palette.common.black,
    mt: 3,

    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      px: 2,
      fontSize: "0.625rem",
      maxWidth: 480,

      "& .MuiButton-startIcon": {
        width: "1em",
        height: "auto",
      },
    },

    [theme.breakpoints.down("md")]: {
      alignSelf: "center",
      maxWidth: "initial",
    },
  }),
  link: () => ({
    color: "#00A1FF",
    fontSize: "0.75rem",
    cursor: "pointer",
  }),
};

export default SupportForm;
