import React, { useContext, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { blueGrey } from "@mui/material/colors";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import { Grid, useTheme } from "@mui/material";
import { SxPropsObject } from "../../../../types";

import { ReactComponent as FlashIcon } from "../../../../assets/svg/flash.svg";
import { ReactComponent as EllipsisIcon } from "../../../../assets/svg/ellipsis.svg";
import { AppContext } from "../../../../context";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    value: index,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

//@ts-ignore
const Item = (props): JSX.Element => {
  const { entity } = props;
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isFullWidth = useMediaQuery(theme.breakpoints.down(365));

  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = React.useState(0);
  const { setOpenCallback } = useContext(AppContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setValue(0);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const renderList = (items: Array<string> | null): JSX.Element | null => {
    if (!items || items.length === 0) {
      return null;
    }

    return (
      <>
        {items.map((el, index) => {
          return (
            <Box sx={styles.listItem} key={index}>
              <Typography
                variant="body2"
                component="span"
                color={blueGrey[100]}
              >
                {el}
              </Typography>
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Card sx={styles.card}>
        <Box sx={styles.cardInner}>
          <Box sx={styles.cardPrimaryImageBox}>
            {entity.secondaryImage && (
              <Box sx={styles.cardSecondaryImageWrapper}>
                <Box sx={styles.cardSecondaryImageBox}>
                  <CardMedia
                    component="img"
                    sx={styles.cardSecondaryImage}
                    image={entity.secondaryImage}
                    alt={entity.connection}
                  />
                </Box>
              </Box>
            )}
            {entity.primaryImage && (
              <CardMedia
                component="img"
                sx={styles.cardPrimaryImage}
                image={entity.primaryImage}
                alt={entity.name}
              />
            )}
          </Box>
        </Box>
        <CardContent sx={styles.cardContent}>
          {entity.type && (
            <Typography variant="body2" component="p" color="text.secondary">
              {entity.type}
            </Typography>
          )}
          <Typography variant="h6" component="h6" color="text.primary">
            {entity.name}
          </Typography>
          <Typography variant="body2" component="p" color={blueGrey[100]}>
            Тип підключення:
            <br />
            {entity.connection}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            variant="contained"
            endIcon={<EllipsisIcon />}
            onClick={handleOpen}
            sx={styles.cardActionsBtn}
          >
            Детальніше
          </Button>
        </CardActions>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalInner}>
          <Box sx={styles.modalHead}>
            <Typography
              variant="h3"
              component="div"
              color="text.primary"
              sx={{
                maxWidth: "50%",
                mr: 3,
              }}
            >
              {entity.name}
            </Typography>
            <Typography variant="body2" component="div" color={blueGrey[100]}>
              Тип підключення:
              <br />
              {entity.connection}
            </Typography>
          </Box>
          <Box sx={styles.modalContent}>
            {entity.description &&
              entity.description
                .split("\n")
                .map((el: string, index: number) => {
                  return (
                    <Typography
                      key={index}
                      id="modal-modal-description"
                      variant="body2"
                      component="p"
                      sx={{ mt: 2 }}
                    >
                      {el}
                    </Typography>
                  );
                })}
            <Box sx={{ pt: 1 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="secondary"
                textColor="inherit"
                aria-label="Tracker details"
              >
                {entity.characteristics && (
                  <Tab
                    sx={styles.tab}
                    label="Технічні характеристики"
                    {...a11yProps(0)}
                  />
                )}
                {entity.interfaces && (
                  <Tab sx={styles.tab} label="Інтерфейси" {...a11yProps(1)} />
                )}
                {entity.features && (
                  <Tab sx={styles.tab} label="Функції" {...a11yProps(2)} />
                )}
                {entity.usage && (
                  <Tab
                    sx={styles.tab}
                    label="Сфери використання"
                    {...a11yProps(3)}
                  />
                )}
                {entity.certificates && (
                  <Tab sx={styles.tab} label="Сертифікати" {...a11yProps(4)} />
                )}
              </Tabs>
              <Box>
                {entity.characteristics && (
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    {renderList(entity.characteristics)}
                  </TabPanel>
                )}
                {entity.interfaces && (
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    {renderList(entity.interfaces)}
                  </TabPanel>
                )}
                {entity.features && (
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    {renderList(entity.features)}
                  </TabPanel>
                )}
                {entity.usage && (
                  <TabPanel value={value} index={3} dir={theme.direction}>
                    {renderList(entity.usage)}
                  </TabPanel>
                )}
                {entity.certificates && (
                  <TabPanel value={value} index={4} dir={theme.direction}>
                    {renderList(entity.certificates)}
                  </TabPanel>
                )}
              </Box>
            </Box>
            {entity.note && (
              <Typography
                id="modal-modal-note"
                variant="body2"
                component="p"
                sx={{ mt: 2 }}
              >
                {entity.note}
              </Typography>
            )}
          </Box>
          <Box sx={styles.modalFooter}>
            <Grid container spacing={1}>
              <Grid item xs={isFullWidth ? 12 : 6} sm={6} md="auto">
                <Button
                  fullWidth={isDownMd}
                  variant="contained"
                  startIcon={<FlashIcon />}
                  onClick={() => setOpenCallback(true)}
                  sx={styles.modalFooterBtn}
                >
                  Залишити заявку
                </Button>
              </Grid>
              <Grid item xs={isFullWidth ? 12 : 6} sm={6} md="auto">
                <Button
                  fullWidth={isDownMd}
                  variant="contained"
                  onClick={handleClose}
                  sx={(theme) => {
                    let style;
                    if (typeof styles.modalFooterBtn === "function") {
                      style = styles.modalFooterBtn(theme);
                    }
                    return {
                      ...style,
                      background: (theme) => theme.palette.background.paper,
                    };
                  }}
                >
                  Закрити
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const styles: SxPropsObject = {
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    py: 2,
    background: "transparent",
    boxShadow: "none",
    borderRadius: "12px",
  },
  cardPrimaryImageBox: () => ({
    position: "relative",
    height: 0,
    pb: "79.36%",
  }),
  cardPrimaryImage: () => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  }),
  cardSecondaryImageWrapper: () => ({
    position: "absolute",
    top: 0,
    left: "10%",
    width: "31.74%",
    height: "auto",
    borderRadius: "50%",
    border: (theme) => `1px solid ${theme.palette.secondary.main}`,
    background: blueGrey[100],
    zIndex: 2,
  }),
  cardSecondaryImageBox: () => ({
    position: "relative",
    height: 0,
    pb: "100%",
    borderRadius: "50%",
  }),
  cardSecondaryImage: () => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    borderRadius: "50%",
  }),
  cardOrderBtn: (theme) => ({
    backgroundColor: "#FF1D25",
    color: "#fff",
    mt: 2,
    p: 1,
    borderRadius: 20,
    fontSize: "1rem",
    textTransform: "initial",
    minWidth: 182,
    "&:hover": { backgroundColor: "#d00007" },

    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      py: 1.25,
      px: 4,
      fontSize: "0.625rem",

      "& .MuiButton-startIcon": {
        width: "1em",
        height: "auto",
      },
    },
  }),
  tab: (theme) => ({
    px: 0,
    textTransform: "initial",
    minWidth: "unset",
    mr: 5,

    [theme.breakpoints.down("md")]: {
      mr: 3,
    },

    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
  }),
  listItem: {
    display: "flex",
    alignItems: "flex-start",
    mb: 1,

    "&::before": {
      content: '""',
      flexShrink: 0,
      width: "0.42em",
      height: "0.42em",
      mr: "0.42em",
      position: "relative",
      top: "0.34em",
      borderRadius: "50%",
      border: (theme) => `1px solid ${theme.palette.secondary.main}`,
    },
  },
  cardInner: (theme) => ({
    px: 7,
    width: "100%",

    [theme.breakpoints.down(1200)]: {
      px: 5,
    },

    [theme.breakpoints.down("md")]: {
      px: 2,
    },
  }),
  cardContent: {
    flexGrow: 1,
    textAlign: "center",
  },
  cardActionsBtn: (theme) => ({
    p: 1,
    borderRadius: 20,
    textTransform: "initial",
    minWidth: 182,

    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      py: 1.25,
      px: 4,
      fontSize: "0.625rem",

      "& .MuiButton-startIcon": {
        width: "1em",
        height: "auto",
      },
    },
  }),
  modalInner: (theme) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    alignItems: "stretch",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100vw - 2px)",
    maxWidth: 700,
    bgcolor: "common.black",
    border: `1px solid ${theme.palette.secondary.main}`,
    p: 5,
    borderRadius: 2,
    maxHeight: "calc(100vh - 2px)",

    [theme.breakpoints.down("md")]: {
      p: 2,
    },
  }),
  modalHead: {
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
  },
  modalContent: (theme) => ({
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    pr: theme.spacing(1),

    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.primary.dark,
    },

    "&::-webkit-scrollbar": {
      width: theme.spacing(0.5),
      height: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  modalFooter: {
    pt: 3,
    flexShrink: 0,
  },
  modalFooterBtn: (theme) => ({
    p: 1,
    borderRadius: 20,
    textTransform: "initial",
    minWidth: {
      sm: 186,
    },

    [theme.breakpoints.down("md")]: {
      py: 1.25,
      px: 4,
      fontSize: "0.625rem",

      "& .MuiButton-startIcon": {
        width: "1em",
        height: "auto",

        "& > svg": {
          fontSize: "1.625em",
          height: "1em",
          width: "auto",
        },
      },
    },
  }),
  trackerPrice: {
    fontSize: "1.6875rem",
    whiteSpace: "nowrap",
    color: "#fff",
  },
  trackerPriceDescription: (theme) => ({
    fontSize: "1rem",
    ml: 1,
    color: "#fff",

    [theme.breakpoints.down("sm")]: {
      fontSize: "0.625rem",
    },
  }),
  trackerPriceInfo: (theme) => ({
    fontSize: "1rem",
    color: "#F3971E",
    whiteSpace: "nowrap",

    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  }),
  priceWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default Item;
