export const TIMELINE_ITEMS = [
  {
    title: 'Початок проекту Alarius',
    date: '01.2020'
  },
  {
    title: 'Програма раннього доступу (EAP)',
    date: '03.2021'
  },
  {
    title: 'Перемога в 24-му USF Pitch Day',
    date: '04.2021'
  },
  {
    title: '1 місце з 300 стартапів у конкурсі ДТЕК',
    date: '05.2021'
  },
  {
    title: 'Перші 300 одиниць транспортних засобів у системі AutoBI',
    date: '06.2021'
  },
  {
    title: 'Участь у Web Summit 2021 (Лісабон, Португалія)',
    date: '11.2021'
  },
  {
    title: '1 місце з усіх українських стартапів на Dubai EXPO 2020',
    date: '01.2022'
  },
  {
    title: 'Відкриття філіалу в Англії',
    date: '06.2022'
  }
];
