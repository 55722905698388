import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { SxPropsObject } from "../../types";
import { ReactComponent as HeartIcon } from "../../assets/svg/heartUkraine.svg";

const Footer = () => {
  const navigate = useNavigate();

  const handleLinkClick = (to: string) => () => {
    navigate(to);
    window.scrollTo(0, 0);
  };

  return (
    <Box sx={styles.container} component="footer">
      <Container>
        <Box sx={styles.content}>
          <Box sx={styles.nav}>
            <Link
              variant="body2"
              color="text.primary"
              component="span"
              onClick={handleLinkClick("/support")}
              sx={styles.link}
            >
              Технічна підтримка
            </Link>
            <Link
              variant="body2"
              color="text.primary"
              component="span"
              onClick={handleLinkClick("/agreement")}
              sx={styles.link}
            >
              Правила та умови
            </Link>
            <Link
              variant="body2"
              color="text.primary"
              component="span"
              onClick={handleLinkClick("/terms")}
              sx={styles.link}
            >
              Правила користування програмою
            </Link>
          </Box>
          <Box sx={styles.info}>
            <Typography variant="body2" color="text.primary" sx={styles.text}>
              {`© Alarius ${new Date().getFullYear()}| Всі права захищено.`}
            </Typography>

            <Typography variant="body2" color="text.primary" sx={styles.text}>
              Зроблено з{" "}
              <HeartIcon
                style={{
                  height: "1.2em",
                  verticalAlign: "middle",
                  margin: "0 4px",
                }}
              />{" "}
              в Україні. Слава Україні!
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const styles: SxPropsObject = {
  container: () => ({
    py: 2,
    bgcolor: "common.black",
    color: "text.primary",
  }),
  content: (theme) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }),
  info: (theme) => ({
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      mt: 2,
    },
    [theme.breakpoints.down("sm")]: {
      mt: 3,
      flexDirection: "column",
      alignItems: "center",
    },
  }),
  nav: (theme) => ({
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  }),
  link: (theme) => ({
    cursor: "pointer",
    fontSize: "0.8125rem",
    fontWeight: 400,
    mr: 2,
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.down("md")]: {
      mr: 1,
    },
  }),
  text: (theme) => ({
    fontSize: "0.8125rem",
    fontWeight: 400,
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.7rem",
      mr: 1,
    },
  }),
};

export default Footer;
