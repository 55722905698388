import React, { useState } from "react";
import { useTimer } from "react-timer-hook";
import Box from "@mui/material/Box";
import { SxPropsObject } from "../../types";
import Typography from "@mui/material/Typography";
import { ReactComponent as SeparatorIcon } from "../../assets/svg/timeSeparator.svg";
import { PROPOSAL_EXPIRED_MILLISECONDS } from "../../config/constants";
import { ContainerBox } from "./styled";

type TimerValueProps = {
  label: string | null;
  value: number | null;
};

const TimerValue: React.FC<TimerValueProps> = ({
  label,
  value,
}): JSX.Element => {
  return (
    <Box sx={styles.item}>
      <Typography component="div" color="text.primary" sx={styles.number}>
        {String(value).padStart(2, "0")}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        color="text.primary"
        sx={styles.label}
      >
        {label}
      </Typography>
    </Box>
  );
};

const Timer = () => {
  const [expired, setExpired] = useState(false);
  const expiredTime = Number(localStorage.getItem("proposalExpiredTime"));
  const expiredAt = new Date(expiredTime);
  const expiryTimestamp = new Date();
  if (expiredTime) {
    expiryTimestamp.setSeconds(
      (expiredAt.getTime() - expiryTimestamp.getTime()) / 1000
    );
  } else {
    expiryTimestamp.setSeconds(PROPOSAL_EXPIRED_MILLISECONDS / 1000);
  }

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      setExpired(true);
    },
  });

  return (
    <ContainerBox sx={styles.container} visible={!expired}>
      {!expired ? (
        <>
          <Typography variant="body1" component="div" color="text.primary">
            Отримай унікальний чекліст
          </Typography>
          <Box sx={styles.content}>
            <TimerValue label="годин" value={hours} />
            <SeparatorIcon />
            <TimerValue label="хвилин" value={minutes} />
            <SeparatorIcon />
            <TimerValue label="секунд" value={seconds} />
          </Box>
        </>
      ) : (
        <Typography variant="body1" component="div" color="text.primary">
          Час унікальної пропозиції минув
        </Typography>
      )}
    </ContainerBox>
  );
};

const styles: SxPropsObject = {
  container: {},
  content: (theme) => ({
    display: "flex",
    alignItems: "center",
    mx: -2.4,

    [theme.breakpoints.down("md")]: {
      mx: -1.5,
    },

    "& > svg": {
      position: "relative",
      bottom: (theme) => theme.spacing(1),
      width: 8,
      height: "auto",

      [theme.breakpoints.down("md")]: {
        width: 4,
        bottom: (theme) => theme.spacing(0.5),
      },
    },
  }),
  item: (theme) => ({
    fontSize: "4rem",
    mx: 2.4,

    [theme.breakpoints.down(1200)]: {
      fontSize: "3.1875rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
      mx: 1.5,
    },
  }),
  number: (theme) => ({
    fontWeight: 200,
    fontSize: "inherit",
    width: "1.26em",
    height: "auto",
    lineHeight: 1,
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
    },
  }),
  label: {
    lineHeight: 1,
  },
};

export default Timer;
