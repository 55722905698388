import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { USAGE_ITEMS } from "./constants";
import { UsageBox, UsageLine } from "./styled";
import Button from "@mui/material/Button";
import { ReactComponent as FlashIcon } from "../../../assets/svg/flash.svg";
import lineImg from "../../../assets/svg/proposalLine.svg";
import { SxPropsObject } from "../../../types";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { useTheme } from "@mui/material";
import { AppContext } from "../../../context";

const Usage = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const { setOpenCallback } = useContext(AppContext);

  return (
    <UsageBox id="usage" sx={styles.container}>
      <Container sx={{ zIndex: 10 }}>
        <Typography
          variant="h3"
          color="text.primary"
          sx={styles.heading}
          data-aos="flip-up"
        >
          Як почати користуватись?
        </Typography>
        <Box sx={styles.content}>
          {isUpMd && <UsageLine src={lineImg} />}
          <Grid container spacing={isDownMd ? 2 : 6} justifyContent="center">
            {USAGE_ITEMS.map((item, index) => {
              const aosDelay = 200 + index * 100;

              return (
                <Grid item key={index} xs={12} sm={6} md={3}>
                  <Card
                    data-aos="fade-up"
                    data-aos-delay={aosDelay}
                    sx={styles.card}
                  >
                    <Typography
                      variant="h6"
                      component="h6"
                      color="common.black"
                      sx={styles.cardNumber}
                    >
                      {item.key}
                    </Typography>
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        p: 0,
                        ml: 0.3,
                        [theme.breakpoints.down("md")]: {
                          ml: 1,
                        },
                        [theme.breakpoints.down("sm")]: {
                          ml: 0.85 * (index + 1),
                        },
                      }}
                      data-aos="zoom-in"
                      data-aos-delay={aosDelay + 1000}
                    >
                      <Typography
                        variant="h6"
                        component="h6"
                        color="text.primary"
                        gutterBottom
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        color="text.primary"
                      >
                        {item.content}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box sx={styles.actions} data-aos="zoom-out" data-aos-delay={400}>
          <Button
            variant="contained"
            startIcon={<FlashIcon />}
            sx={styles.actionsButton}
            fullWidth={isDownMd}
            onClick={() => setOpenCallback(true)}
          >
            Залишити заявку
          </Button>
        </Box>
      </Container>
    </UsageBox>
  );
};

const styles: SxPropsObject = {
  container: (theme) => ({
    py: 30,

    [theme.breakpoints.down("md")]: {
      py: 10,
    },
  }),
  heading: (theme) => ({
    textAlign: "center",
    mb: 10,

    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      mb: 5,
    },
  }),
  content: (theme) => ({
    px: 3,
    position: "relative",

    [theme.breakpoints.down("md")]: {
      px: 0,
    },
  }),
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    background: "transparent",
    boxShadow: "none",
    border: "none",
    position: "relative",
    overflow: "initial",
  },
  cardNumber: (theme) => ({
    mb: 1,
    fontSize: "5em",
    width: "1em",
    height: "auto",
    lineHeight: 1,
    position: "absolute",
    left: 0,
    top: 0,
    fontStyle: "italic",
    fontWeight: 900,
    pointerEvents: "none",
    margin: 0,
    transform: "translate(-36%, -36%)",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      fontSize: "4em",
      transform: "translate(-16%, -22%)",
    },
  }),
  actions: (theme) => ({
    pt: 7,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      pt: 4,
    },
  }),
  actionsButton: (theme) => ({
    p: 1.75,
    borderRadius: 20,
    textTransform: "initial",
    minWidth: 246,
    background: (theme) => theme.palette.common.black,

    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      py: 1,
      px: 2,
      fontSize: "0.625rem",
      maxWidth: 480,

      "& .MuiButton-startIcon": {
        width: "1em",
        height: "auto",
      },
    },
  }),
};

export default Usage;
