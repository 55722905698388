import axios from "axios";
import {SLACK_WEBHOOK_URL, CRM_CREATE_ORDER_ENDPOINT} from "./config/constants";
import {CallbackValues, ProposalValues} from "./types";

export const sendToSlack = async (data: any) => {
  return axios.post(SLACK_WEBHOOK_URL, JSON.stringify(data), {
    withCredentials: false,
    transformRequest: [(data, headers) => {
      //@ts-ignore
      delete headers?.post["Content-Type"]
      return data;
    }]
  })
}

export const sendToCRM = async (values: CallbackValues|ProposalValues) => {
  function getDataForCRM(values: CallbackValues|ProposalValues) {
    if  ((values as ProposalValues).company !== undefined) {
        return {
            "first_name": values.name,
            "phone": values.email,
            "email": values.email,
            "client_comment": {
                "company": (values as ProposalValues).company,
                "vehicles_quantity": (values as ProposalValues).vehicle,
            }
        }
    }

    return {
          "first_name": values.name,
          "phone": (values as CallbackValues).phone,
          "email": values.email
        }
  }

  return axios.post(CRM_CREATE_ORDER_ENDPOINT, getDataForCRM(values), {
    transformRequest: [(data, headers) => {

        if (headers) {
        //@ts-ignore
        headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      }

      return Object.keys(data)
          .map((key) => `${key}=${encodeURIComponent(
              typeof data[key] === 'object' 
                  ? (Object.keys(data[key]).map((i) => `${i.replace('_', ' ')}: ${data[key][i]}`)).join("\n")
                  : data[key]
          )}`)
          .join('&');
    }]
  });
}
