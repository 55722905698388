// @ts-nocheck

const StatementTemplate = () => {
  return (
    <div>
      <p align="center" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <b>ЗАЯВА ПРО ПРИЄДНАННЯ № </b>
      </p>
      <p
        align="center"
        style={{
          lineHeight: "100%",
          textIndent: "0.25in",
          marginBottom: "0in",
        }}
      >
        <b>до Договору про надання доступу до Програми "AutoBI"</b>
      </p>
      <p style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <p style={{ lineHeight: "100%", marginBottom: "0in" }}>
        Київ _ _________ 2022 р.
      </p>
      <p style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <p style={{ lineHeight: "100%", marginBottom: "0in" }}>
        КОРИСТУВАЧ: ________________________________________________
      </p>
      <p style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <font size={1} style={{ fontSize: "8pt" }}>
          {" "}
          (назва, організаційно-правова форма юридичної особи або ПІБ фізичної
          особи – підприємця)
        </font>
      </p>
      <p align="justify" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        цією Заявою приєднується до Договору про надання доступу до Програми
        "AutoBI" в редакції, яка діє в день підписання цієї Заяви, і
        беззастережно приймає положення Договору про надання доступу до Програми
        "AutoBI", Правил користування Програмою, Додаткової угоди про оренду
        обладнання до Договору про надання доступу до Програми "AutoBI" та
        Додатку До Договору про надання доступу до Програми "AutoBI" (Тарифи) в
        редакції, чинній в день підписання цієї Заяви про приєднання.
      </p>
      <p align="justify" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <table cellPadding={7} cellSpacing={0}>
        <colgroup>
          <col />
          <col />
        </colgroup>
        <thead>
          <tr valign="top">
            <td style={{ border: "none", padding: "0in" }}>
              <p align="center" style={{ orphans: 0, widows: 0 }}>
                <b>КОРИСТУВАЧ</b>
              </p>
            </td>
            <td style={{ border: "none", padding: "0in" }}>
              <p align="center" style={{ orphans: 0, widows: 0 }}>
                <b>ОПЕРАТОР</b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td style={{ border: "none", padding: "0in" }}>
              <p
                align="justify"
                style={{ orphans: 0, widows: 0, marginBottom: "0in" }}
              >
                <br />
              </p>
              <p
                align="justify"
                style={{ orphans: 0, widows: 0, marginBottom: "0in" }}
              >
                _______________________
              </p>
              <p align="justify" style={{ orphans: 0, widows: 0 }}>
                <br />
              </p>
            </td>
            <td style={{ border: "none", padding: "0in" }}>
              <p
                align="justify"
                style={{ orphans: 0, widows: 0, marginBottom: "0in" }}
              >
                <br />
              </p>
              <p
                align="justify"
                style={{ orphans: 0, widows: 0, marginBottom: "0in" }}
              >
                ________________________
              </p>
              <p align="justify" style={{ orphans: 0, widows: 0 }}>
                Тарас НІКОЛЕНКО
              </p>
            </td>
          </tr>
        </thead>
      </table>
      <p align="justify" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <p align="center" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <p align="center" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <p align="center" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        СПЕЦИФІКАЦІЯ
      </p>
      <p style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <p align="justify" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        Згідно умов Договору про надання доступу до Програми "AutoBI", Правил
        користування Програмою, Додаткової угоди про оренду обладнання до
        Договору про надання доступу до Програми "AutoBI" та Додатку А до
        Договору про надання доступу до Програми "AutoBI" (Тарифи) в редакції,
        чинній в день підписання цієї Заяви про приєднання, КОРИСТУВАЧ отримує
        послуги на наступне обладнання:
      </p>
      <p align="justify" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <p align="justify" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        ОПЕРАТОР передав, а КОРИСТУВАЧ прийняв в строкове платне користування
        наступне обладнання:
      </p>
      <p align="justify" style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <table cellPadding={7} cellSpacing={0}>
        <colgroup>
          <col />
          <col />
        </colgroup>
        <thead>
          <tr valign="top">
            <td style={{ border: "1px solid #fff", padding: "0in 0.08in" }}>
              <p align="center" style={{ orphans: 0, widows: 0 }}>
                Найменування
              </p>
            </td>
            <td style={{ border: "1px solid #fff", padding: "0in 0.08in" }}>
              <p align="center" style={{ orphans: 0, widows: 0 }}>
                Кількість
              </p>
            </td>
          </tr>
          <tr />
          <tr />
          <tr />
          <tr />
          <tr />
          <tr />
          <tr />
          <tr />
          <tr />
          <tr valign="top">
            <td style={{ border: "1px solid #fff", padding: "0in 0.08in" }}>
              <p align="center" style={{ orphans: 0, widows: 0 }}>
                <br />
              </p>
            </td>
            <td style={{ border: "1px solid #fff", padding: "0in 0.08in" }}>
              <p align="center" style={{ orphans: 0, widows: 0 }}>
                <br />
              </p>
            </td>
          </tr>
        </thead>
      </table>
      <p style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <p style={{ lineHeight: "100%", marginBottom: "0in" }}>
        Обладнання справне і допускає його використання за призначенням.
        Зауважень до стану та якості обладнання КОРИСТУВАЧ не має.
      </p>
      <p style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
      <table cellPadding={7} cellSpacing={0}>
        <colgroup>
          <col />
          <col />
        </colgroup>
        <thead>
          <tr valign="top">
            <td style={{ border: "none", padding: "0in" }}>
              <p align="center" style={{ orphans: 0, widows: 0 }}>
                <b>КОРИСТУВАЧ</b>
              </p>
            </td>
            <td style={{ border: "none", padding: "0in" }}>
              <p align="center" style={{ orphans: 0, widows: 0 }}>
                <b>ОПЕРАТОР</b>
              </p>
            </td>
          </tr>
          <tr valign="top">
            <td style={{ border: "none", padding: "0in" }}>
              <p
                align="justify"
                style={{ orphans: 0, widows: 0, marginBottom: "0in" }}
              >
                <br />
              </p>
              <p
                align="justify"
                style={{ orphans: 0, widows: 0, marginBottom: "0in" }}
              >
                _______________________
              </p>
              <p align="justify" style={{ orphans: 0, widows: 0 }}>
                <br />
              </p>
            </td>
            <td style={{ border: "none", padding: "0in" }}>
              <p
                align="justify"
                style={{ orphans: 0, widows: 0, marginBottom: "0in" }}
              >
                <br />
              </p>
              <p
                align="justify"
                style={{ orphans: 0, widows: 0, marginBottom: "0in" }}
              >
                ________________________
              </p>
              <p align="justify" style={{ orphans: 0, widows: 0 }}>
                Тарас НІКОЛЕНКО
              </p>
            </td>
          </tr>
        </thead>
      </table>
      <p style={{ lineHeight: "100%", marginBottom: "0in" }}>
        <br />
      </p>
    </div>
  );
};

export default StatementTemplate;
