import React, {useContext} from 'react';
import {AppContext} from "../../../context";
import {SxPropsObject} from "../../../types";
import Box from '@mui/material/Box';
import ModalMui  from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import {ReactComponent as FlashIcon} from "../../../assets/svg/flash.svg";
import Form from '../Form';

type Props = {
}

const Modal: React.FC<Props> = () => {
  const {openCallback, setOpenCallback} = useContext(AppContext);

  return (
    <ModalMui
      open={openCallback}
      onClose={() => setOpenCallback(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box  sx={styles.modalInner}>
        <Box sx={styles.container}>
          <Box sx={styles.head}>
            <Typography
              sx={styles.title}
              variant="h6"
              component="h6"
              color="text.primary"
            >
              <FlashIcon/> Залишити заявку
            </Typography>
          </Box>
          <Form variant="primary"/>
        </Box>
      </Box>
    </ModalMui>
  );
};

const styles: SxPropsObject = {
  container: theme => ({
    px: 5,
    py: 4,
    borderRadius: (theme) => theme.spacing(3),
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: (theme) => theme.palette.common.black,
    border: `1px solid ${theme.palette.secondary.main}`,
    boxShadow: '0 0 64px rgba(255, 255, 255, 0.15)',
    maxWidth: (theme) => theme.spacing(50),
    width: 'calc(100% - 2px)',

    [theme.breakpoints.down('md')]: {
      p: 2,
    },
  }),
  head: {
    display: 'flex',
  },
  title: {
    '& > svg': {
      display: 'inline-flex',
      marginRight: 1,
      verticalAlign: "middle",
    }
  },
}

export default Modal;

