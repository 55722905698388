import { Box, Typography } from "@mui/material";
import { SxPropsObject } from "../../../types";

const TermsWarningMessage = () => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h3" sx={styles.title}>
        Шановний клієнте!
      </Typography>
      <Typography variant="subtitle1" mt={1.25}>
        Перед початком використання програми «AutoBI», будь ласка, уважно
        ознайомтесь з цією клієнтською угодою. Ця угода регулює відносини
        між Вами і програмою «AutoBI» умови використання Вами програми «AutoBI».
        Ви маєте право використовувати програму лише у випадку прийняття умов
        цієї угоди. Початок використання Вами програми означає беззастережне
        укладення Вами цієї угоди та Вашу повну згоду з усіма її умовами.
      </Typography>
    </Box>
  );
};

const styles: SxPropsObject = {
  container: (theme) => ({
    backgroundColor: "#275CDE",
    borderRadius: "24px",
    p: 5,
    mt: 12,
    [theme.breakpoints.down("md")]: {
      p: 3,
      mt: 8,
    },
  }),
  title: () => ({
    fontSize: 20,
  }),
};

export default TermsWarningMessage;
