export const USAGE_ITEMS = [
  {
    key: "I",
    title: "Залишаєш заявку",
    content: "Залиш контактні дані для консультації та формування вартості",
  },
  {
    key: "II",
    title: "Отримуєш обладнання",
    content: "Отримай обладнання та доступи до системи",
  },
  {
    key: "III",
    title: "Активуєш та підключаєш",
    content: "Підключаєш трекер до авто та активуєш його у системі",
  },
  {
    key: "IV",
    title: "Поїхали!",
    content: "Готово! Поринь у світ управління автопарку наступного покоління",
  },
];
