// @ts-nocheck

const TeriffsTemplate = () => {
  return (
    <div>
      <p align="center" style={{ lineHeight: "108%", marginBottom: "0in" }}>
        <font>
          <b>ДОДАТОК А</b>
        </font>
      </p>
      <p align="left" style={{ lineHeight: "108%", marginBottom: "0in" }}>
        <font>
          <b>до Договору про надання доступу до Програми "AutoBI"</b>
        </font>
      </p>
      <p align="right" style={{ lineHeight: "108%", marginBottom: "0in" }}>
        <br />
      </p>
      <p
        className="western"
        align="justify"
        style={{
          lineHeight: "108%",
          textIndent: "0.25in",
          marginBottom: "0in",
        }}
      >
        <font>
          <b>Товариство з обмеженою відповідальністю "АЛАРІУС ФМС"</b>
        </font>
        <font>
          , юридична особа належним чином створена, зареєстрована та діюча
          згідно з чинним законодавством України, з кодом ЄДРПОУ 43572863 та
          місцезнаходженням 04119, місто Київ, вул. Дегтярівська, будинок 25/1,
          поверх 3, в особі директора Тараса НІКОЛЕНКО, який діє на підставі
          Статуту, (іменоване надалі "Оператор")
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{ lineHeight: "108%", marginBottom: "0in" }}
      >
        <font>
          цим встановлює Тарифи на послуги з надання доступу до Програми згідно
          Договору про надання доступу до Програми "AutoBI" та оренду GPS-
          обладнання.
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{ lineHeight: "108%", marginBottom: "0in" }}
      >
        <font>
          Цей Додаток А є невід'ємною частиною Договору про надання доступу до
          Програми "AutoBI, який відповідно до статті 634 Цивільного кодексу
          України, може бути укладений шляхом приєднання Користувача до нього в
          цілому, шляхом надання відповідної заяви про приєднання, яка доступна
          за адресою{" "}
        </font>
        <font>
          <a href="/agreement#statement" target="_blank" rel="noreferrer">
            <font color="#0563c1">
              <font>
                <u>Заява про приєднання</u>
              </font>
            </font>
          </a>
        </font>
        <font>. Користувач не може запропонувати свої умови Договору.</font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          lineHeight: "108%",
          textIndent: "0.49in",
          marginBottom: "0in",
          border: "none",
          padding: "0in",
        }}
      >
        <br />
      </p>
      <p
        className="western"
        align="justify"
        style={{ lineHeight: "108%", marginBottom: "0in" }}
      >
        <font>
          1. Вартість користування Програми "AutoBI" та оренди GPS-обладнання є
          комплексною послугою, яка надається "Користувачу" згідно Договору з
          Додатками та Додатковими угодами та становить:
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{ lineHeight: "108%", marginBottom: "0in" }}
      >
        <br />
      </p>
      <div style={{ overflow: "auto" }}>
        <table width={637} cellPadding={2} cellSpacing={0}>
          <colgroup>
            <col width={136} />
            <col width={166} />
            <col width={175} />
            <col width={142} />
          </colgroup>
          <tbody>
            <tr>
              <td
                width={136}
                height={12}
                bgcolor="#a4c2f4"
                style={{
                  background: "#a4c2f4",
                  border: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>Тип та модель GPS-обладнання</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={166}
                bgcolor="#a4c2f4"
                style={{
                  background: "#a4c2f4",
                  borderTop: "1px solid #000000",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>
                        Платіж в перший місяць використання, за одиницю
                        обладнання (без ПДВ)
                      </b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={175}
                bgcolor="#a4c2f4"
                style={{
                  background: "#a4c2f4",
                  borderTop: "1px solid #000000",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0, marginBottom: "0in" }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>
                        Місячна абонплата за одиницю обладнання у перші 12
                        місяців
                      </b>
                    </font>
                  </font>
                </p>
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>(без ПДВ)</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={142}
                bgcolor="#a4c2f4"
                style={{
                  background: "#a4c2f4",
                  borderTop: "1px solid #000000",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0, marginBottom: "0in" }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>
                        Місячна абонплата після 1 року оплати, за одиницю
                        обладнання
                      </b>
                    </font>
                  </font>
                </p>
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>(без ПДВ)</b>
                    </font>
                  </font>
                </p>
              </td>
            </tr>
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr>
              <td
                width={136}
                height={13}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #000000",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>Plug and play – Teltonika FMP 100</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={166}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>грн.327.21</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={175}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>грн.300.20</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={142}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>грн.174.24</b>
                    </font>
                  </font>
                </p>
              </td>
            </tr>
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr>
              <td
                width={136}
                height={13}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #000000",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>Terminal – Teltonika FMT 100</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={166}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>грн.314.62</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={175}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>грн.282.16</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={142}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>грн.174.24</b>
                    </font>
                  </font>
                </p>
              </td>
            </tr>
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr />
            <tr>
              <td
                width={136}
                height={12}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #000000",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>CAN – Teltonika FMB 003</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={166}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>грн.310.35</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={175}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>грн.276.06</b>
                    </font>
                  </font>
                </p>
              </td>
              <td
                width={142}
                bgcolor="#d9ead3"
                style={{
                  background: "#d9ead3",
                  borderTop: "1px solid #cccccc",
                  borderBottom: "1px solid #000000",
                  borderLeft: "1px solid #cccccc",
                  borderRight: "1px solid #000000",
                  padding: "0.02in 0.03in",
                }}
              >
                <p
                  className="western"
                  align="left"
                  style={{ orphans: 0, widows: 0 }}
                >
                  <font face="Arial, serif">
                    <font size={2} style={{ fontSize: "10pt", color: "#000" }}>
                      <b>грн.174.24</b>
                    </font>
                  </font>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        className="western"
        align="justify"
        style={{ lineHeight: "108%", marginBottom: "0.11in" }}
      >
        <br />
        <br />
      </p>
      <p
        className="western"
        align="justify"
        style={{ lineHeight: "108%", marginBottom: "0.11in" }}
      >
        <font>
          2. Вартість Комплексної послуги включає у себе користування програмою
          "AutoBI" згідно обраного Тарифу та оренду обраного GPS обладнання, з
          урахуванням вартості сім-картки для передачі даних.
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          marginBottom: "0in",
          background: "#000",
        }}
      >
        <font>
          3. Оператор не є платником ПДВ, Операції з постачання програмної
          продукції звільнені від оподаткування податком на додану вартість у
          відповідності із п.26-1 Підрозділу 2 розділу XX Податкового кодексу
          України.
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{ lineHeight: "108%", marginBottom: "0.11in" }}
      >
        <br />
        <br />
      </p>
      <p align="justify" style={{ marginBottom: "0in" }}>
        <font>
          <b>ОПЕРАТОР:</b>
        </font>
      </p>
      <p align="justify" style={{ marginBottom: "0in" }}>
        <font>
          <b>Товариство з обмеженою відповідальністю "АЛАРІУС ФМС"</b>
        </font>
      </p>
      <p align="justify" style={{ marginBottom: "0in" }}>
        <font>
          <b>Код ЄДРПОУ:</b>
        </font>
        <font> 43572863</font>
      </p>
      <p align="justify" style={{ marginBottom: "0in" }}>
        <font>
          <b>ІПН</b>
        </font>
        <font>: 265020079987</font>
      </p>
      <p align="justify" style={{ marginBottom: "0in" }}>
        <font>
          <b>Місцезнаходження:</b>
        </font>
        <font>
          04119, місто Київ, вул. Дегтярівська, будинок 25/1, поверх 3
        </font>
      </p>
      <p align="justify" style={{ marginBottom: "0in" }}>
        <font>
          <b>Р/р:</b>
        </font>
        <font>UA333052990000026008006815625,</font>
      </p>
      <p align="justify" style={{ marginBottom: "0in" }}>
        <font>
          <b>Банк</b>
        </font>
        <font>: ПАТ «ПРИВАТБАНК», м. Київ,</font>
      </p>
      <p align="justify" style={{ marginBottom: "0in" }}>
        <font>
          <b>МФО</b>
        </font>
        <font>: 300711 </font>
      </p>
      <p align="justify" style={{ marginBottom: "0in" }}>
        <br />
      </p>
      <p align="justify" style={{ marginBottom: "0in" }}>
        <font>
          <b>Директор</b>
        </font>
        <font> Тарас НІКОЛЕНКО</font>
      </p>
      <p
        className="western"
        style={{ lineHeight: "108%", marginBottom: "0.11in" }}
      >
        <br />
        <br />
      </p>
    </div>
  );
};

export default TeriffsTemplate;
