import React from 'react';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import {Circle} from "../../../styled";
import {Image} from "./styled";
import previewImage from '../../../assets/img/preview.jpeg';
import appsBgImage from '../../../assets/svg/aboutLinesBg.svg';
import appsIphoneImage from '../../../assets/img/iphoneFrame.png';
import {ReactComponent as AppStoreImage} from "../../../assets/svg/appStore.svg";
import {ReactComponent as GooglePlayImage} from "../../../assets/svg/googlePlay.svg";
import Link from '@mui/material/Link';
import TimeLine from "./TimeLine";
import {SxPropsObject} from "../../../types";

const About = () => {

  return (
    <Box id="about" sx={styles.container}>
      <Circle sx={styles.circleFirst}/>
      <Circle sx={styles.circleSecond}/>
      <Container sx={{position: 'relative', zIndex: 10}}>
        <Typography
          variant="h3"
          color="text.primary"
          sx={styles.heading}
          data-aos="flip-up"
        >
          Alarius в деталях
        </Typography>
        <Box
          sx={styles.columns}
          data-aos="fade"
        >
          <Typography variant="body1" color="text.primary" sx={styles.text}>
            Компанія заснована в 2020 році з метою порушити традиційну систему управління автопарком.
            Загальний досвід команди в сферах Automotive та Software становить більше 50 років.
            <br/>
            <br/>
            Alarius — це платформа SaaS, яка збирає та аналізує велику кількість даних, починаючи з палива, температури,
            пробігу, напруги, ваги, швидкості тощо, і закінчуючи збором інформації з усіх можливих датчиків та бортових
            ПК транспортних засобів та обладнання, за допомогою IoT, яка базується на власному розробленому алгоритмі.
            <br/>
            <br/>
            У майбутньому наш інструмент аналізуватиме дані самостійно базуючись на AI технологіях.
            Не потрібно буде аналізувати звіти та графіки, просто отримуйте рішення від системи та виберіть потрібне.
            <br/>
            <br/>
            Ми успішно протестували MVP та отримали винагороди на ринку IT рішень України та Європи.
            <br/>
            <br/>
            Відкрили філіал компанії в Англії та розпочали діяльність на Європейському ринку.
          </Typography>
        </Box>
        <TimeLine/>
        <Box sx={styles.preview}>
          <Image
            src={previewImage}
            alt="Alarius"
            data-aos="zoom-out"
            data-aos-delay={600}
          />
        </Box>
        <Box sx={styles.appsContainer} data-aos="flip-down">
          <Box sx={styles.appsBgLayer}>
            <Image
              src={appsBgImage}
              sx={styles.appsBgImage}
              alt=""
            />
          </Box>
          <Box sx={styles.appsIphoneLayer}>
            <Image
              sx={styles.appsIphoneImage}
              src={appsIphoneImage}
              alt="Alarius App"
              data-aos="slide-up"
              data-aos-delay={400}
            />
          </Box>
          <Box
            sx={styles.appsContent}
            data-aos="fade-left"
            data-aos-delay={400}
          >
            <Typography
              variant="h4"
              component="h4"
              color="text.primary"
              sx={styles.appsHeading}
            >
              Завантажуй
              <br/>{' '}
              мобільний додаток
            </Typography>
            <Box sx={styles.appsLinks}>
              <Link
                href="https://play.google.com/store/apps/details?id=com.alariusmobile"
                target="_blank"
                sx={styles.appsLink}
              >
                <GooglePlayImage/>
              </Link>
              <Link
                href="https://apps.apple.com/ua/app/autobi/id1566134459"
                target="_blank"
                sx={styles.appsLink}
              >
                <AppStoreImage/>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const styles: SxPropsObject = {
  container: (theme) => ({
    pt: 30,
    pb: 15,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      pt: 20,
      pb: 10,
    },
  }),
  circleFirst: () => ({
    top: '20%',
    left: '100%',
    transform: 'translate(-60%, -20%)',
    background: '#330A3A'
  }),
  circleSecond: () => ({
    top: '66%',
    left: 0,
    transform: 'translate(-40%, -50%)',
    background: '#081536'
  }),
  heading: (theme) => ({
    mb: 5,

    [theme.breakpoints.down('md')]: {
      mb: 3,
    },
  }),
  text: () => ({
    mb: 5,
  }),
  columns: (theme) => ({
    columnGap: 8,
    columnCount: 2,

    [theme.breakpoints.down(1200)]: {
      columnGap: 4,
    },
    [theme.breakpoints.down('md')]: {
      columnCount: 1,
    },
  }),
  preview: (theme) => ({
    height: 0,
    pb: '30.3%',
    position: 'relative',
    borderRadius: 3,
    background: (theme) => theme.palette.primary.main,
    overflow: 'hidden',
    mt: 10,

    [theme.breakpoints.down('md')]: {
      mt: 5,
      pb: '90.66666%',
      borderRadius: 0,
      width: `calc(100% + ${theme.spacing(4)})`,
      ml: theme.spacing(-2),
      mr: theme.spacing(-2),
    },
  }),
  appsContainer: (theme) => ({
    position: 'relative',
    borderRadius: 3,
    background: theme.palette.primary.main,
    minHeight: 100,
    p: 4,
    mt: 30,
    mx: 'auto',
    maxWidth: 874,

    [theme.breakpoints.down('md')]: {
      mt: 20,
      borderRadius: 0,
      py: 5,
      px: 2,
      width: `calc(100% + ${theme.spacing(4)})`,
      ml: theme.spacing(-2),
      mr: theme.spacing(-2),
    },
  }),
  appsBgLayer: () => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 3,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }),
  appsBgImage: () => ({
    width: '112%',
    maxWidth: 'unset',
    height: 'auto',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
  }),
  appsIphoneLayer: (theme) => ({
    position: 'absolute',
    width: '100%',
    height: '200%',
    overflow: 'hidden',
    borderRadius: 3,
    left: 0,
    right: 0,
    bottom: 0,

    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  }),
  appsIphoneImage: (theme) => ({
    height: 'auto',
    width: 341,
    objectFit: 'contain',
    left: 0,
    bottom: '-23%',

    [theme.breakpoints.down('md')]: {
      width: 200,
      bottom: '-22%',
      left: '-4%',
    },
  }),
  appsHeading: (theme) => ({
    mb: theme.spacing(0.5),

    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      mb: theme.spacing(4),
    },
  }),
  appsContent: (theme) => ({
    position: 'relative',
    width: '50%',
    ml: 'auto',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',

      'br': {
        display: 'none',
      }
    },
  }),
  appsLinks: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    mx: -1,

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      mx: 'auto',
      maxWidth: '86%',
    },
  }),
  appsLink: (theme) => ({
    m: 1,

    [theme.breakpoints.down('md')]: {
      mx: 0,
    },
  })
}

export default About;
