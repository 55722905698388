import agreement from "../../assets/files/agreement.pdf";
import additional_agreement from "../../assets/files/additional_agreement.pdf";
import statement from "../../assets/files/statement.pdf";
import tariffs from "../../assets/files/tariffs.pdf";
import AgreementTemplate from "./templates/AgreementTemplate";
import AdditionalAgreementTemplate from "./templates/AdditionalAgreementTemplate";
import StatementTemplate from "./templates/StatementTemplate";
import TeriffsTemplate from "./templates/TariffsTemplate/TariffsTemplate";

export const FilesConfig = [
  {
    title: "Договір про надання доступу до Програми «AutoBI»",
    file: agreement,
    Template: AgreementTemplate,
    id: "agreement",
  },
  {
    title: "Додаткова угода про оренду обладнання",
    subTitle: "до Договору про надання доступу до Програми «AutoBI»",
    file: additional_agreement,
    Template: AdditionalAgreementTemplate,
    id: "addition_agreement",
  },
  {
    title: "Додаток А: Тарифи",
    subTitle: "до Договору про надання доступу до Програми «AutoBI»",
    file: tariffs,
    Template: TeriffsTemplate,
    id: "tariffs",
  },
  {
    title: "Заявка про приєднання",
    subTitle: "до Договору про надання доступу до Програми «AutoBI»",
    file: statement,
    Template: StatementTemplate,
    id: "statement",
  },
];
