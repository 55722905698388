import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const Circle = styled(Box)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  height: "auto",
  pointerEvents: "none",
  filter: "blur(256px)",
  width: "40vw",
  borderRadius: "50%",
  zIndex: 1,

  [theme.breakpoints.down("md")]: {
    width: "66vw",
  },

  "&::after": {
    content: '""',
    position: "relative",
    width: "100%",
    height: 0,
    display: "block",
    padding: "0 0 100%",
  },
}));
