// @ts-nocheck

const AdditionalAgreementTemplate = () => {
  return (
    <div>
      <p
        className="western"
        align="left"
        style={{
          textIndent: "0.25in",
          marginBottom: "0in",
        }}
      >
        <font>
          <b>Додаткова угода про оренду обладнання</b>
        </font>
      </p>
      <p
        className="western"
        align="left"
        style={{
          textIndent: "0.25in",
          marginBottom: "0in",
        }}
      >
        <font>
          <b>до Договору про надання доступу до Програми "AutoBI"</b>
        </font>
      </p>
      <p
        className="western"
        align="left"
        style={{
          textIndent: "0.25in",
          marginBottom: "0in",
        }}
      >
        <br />
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.25in",
          marginBottom: "0in",
        }}
      >
        <font>
          <b>м. Київ</b>
        </font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.49in",
          marginBottom: "0in",
          border: "none",
          padding: "0in",
        }}
      >
        <br />
      </p>
      <p
        className="western"
        align="justify"
        style={{
          textIndent: "0.25in",
          marginBottom: "0in",
        }}
      >
        <font>
          <b>Товариство з обмеженою відповідальністю "АЛАРІУСФМС"</b>
        </font>
        <font>
          , юридична особа належним чином створена, зареєстрована та діюча
          згідно з чинним законодавством України, з кодом ЄДРПОУ 43572863 та
          місцезнаходженням 04119, місто Київ, вул. Дегтярівська, будинок 25/1,
          поверх 3, в особі директора Тараса НІКОЛЕНКО, який діє на підставі
          Статуту, (іменоване надалі "Орендодавець")
        </font>
      </p>
      <p className="western" align="justify" style={{ marginBottom: "0in" }}>
        <font>
          цим пропонує фізичним особам, юридичним особам та фізичним особам –
          суб'єктам підприємницької діяльності, кожен з яких окремо іменується
          "Орендар", укласти Додаткову угоду №1 про оренду обладнання до
          Договору про надання доступу до онлайн-сервісу та користування
          програмною продукцією "AutoBI" в порядку і на умовах, передбачених
          вказаним Договором та цією Угодою.
        </font>
      </p>
      <p className="western" align="justify" style={{ marginBottom: "0in" }}>
        <font>
          Відповідно до статті 634 Цивільного кодексу України, ця Додаткова
          угода №1 про оренду обладнання (надалі – "Угода") до Договору Договору
          про надання доступу до онлайн-сервісу та користування програмною
          продукцією "AutoBI" (надалі – "Договір") може бути укладений шляхом
          приєднання Користувача до нього в цілому, шляхом надання відповідної
          заяви про приєднання, яка доступна за адресою
        </font>{" "}
        <font>
          <a href="/agreement#statement" target="_blank" rel="noreferrer">
            <font color="#0563c1">
              <font>
                <u>Заява про приєднання</u>
              </font>
            </font>
          </a>
        </font>
        <font>. Користувач не може запропонувати свої умови Угоди.</font>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          fontVariant: "normal",
          fontStyle: "normal",
          fontWeight: "normal",

          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          textDecoration: "none",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <br />
      </p>
      <ol style={{ listStyleType: "none", padding: 0 }}>
        <li>
          <p
            className="western"
            align="center"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            1. ПРЕДМЕТ УГОДИ:
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginTop: "0.08in",
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <span style={{ background: "transparent" }}>
                        <font>
                          <font size={2} style={{ fontSize: "11pt" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              1.1.
                            </span>
                            <span style={{ fontWeight: "normal" }}>
                              Орендодавець надає, а Орендар приймає в строкове
                              платне користування наступне майно (далі за
                              текстом – "Об’єкт оренди"):
                            </span>
                          </font>
                        </font>
                      </span>
                    </span>
                  </font>
                </span>
              </p>
              <ol style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  1.1.1.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Найменування: GPS-обладнання згідно підписаної
                                  між
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                    <font>Сторонами специфікації.</font>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  1.1.2.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Вартість Об'єкту оренди встановлюється в
                                  рахунку на оплату відповідно до обраного
                                  Тарифу та кількості одиниць обладнання,
                                  визначених специфікацією, яка є невід'ємною
                                  частиною цієї Угоди.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  1.1.3.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Стан майна‚ що орендується‚ на момент передачі
                                  в оренду відповідає основному призначенню.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  1.1.4.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Інші особливості об’єкта оренди, які можуть
                                  вплинути на орендні відносини не виявлені.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  1.1.5.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Об'єкт оренди належить Орендодавцеві на праві
                                  власності та на Об’єкт оренди не встановлено
                                  обмеження чи обтяження та інші права третіх
                                  осіб.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            2. МЕТА ТА УМОВИ ВИКОРИСТАННІ ОБ'ЄКТУ ОРЕНДИ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginTop: "0.08in",
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              2.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Об’єкт оренди передається в оренду для здійснення
                              моніторингу техніки з використанням програмного
                              забезпечення AutoBi.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              marginBottom: "0.08in",
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            3. ТЕРМІН ОРЕНДИ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginTop: "0.08in",
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Термін оренди складає 24 (двадцять чотири) місяці
                              з моменту прийняття об’єкта‚ що орендується.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Після закінчення терміну оренди, орендар має
                              переважне право поновлення його на новий строк. У
                              цьому разі, якщо жодна сторона в термін 30
                              (тридцяти) календарних днів до закінчення цієї
                              Угоди письмово не повідомить про намір його
                              розірвати‚ ця Угода автоматично пролонгується на
                              термін 24 (двадцять чотири) календарних місяці.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Термін оренди може бути скорочений лише за згодою
                              сторін.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              3.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Незважаючи на положення пунктів 3.1. – 3.3. вище,
                              термін оренди не може перевищувати строку надання
                              доступу Орендодавцеві до програмного забезпечення
                              AutoBi згідно відповідного Договору.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              marginBottom: "0.08in",
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            4. ОРЕНДНА ПЛАТА
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginTop: "0.08in",
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              4.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Розмір орендної плати за всі об’єкти оренди, які
                              вказані у Специфікації‚ визначається у
                              відповідному рахунку, виставленому Орендодавцем
                              (Оператором) Орендареві (Користувачу) на підставі
                              Заяви про приєднання згідно Тарифів, визначених
                              Додатком А до Договору.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              4.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Орендна плата сплачується Орендарем щомісячно
                              згідно виставленого Орендодавцем рахунку шляхом
                              здійснення безготівковому переказу на
                              розрахунковий рахунок Орендодавця не пізніше 3
                              (трьох) днів після отримання відповідного рахунку.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              4.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Орендар зобов’язаний протягом 3 (трьох) днів з
                              моменту отримання рахунку‚ перерахувати орендну
                              плату за перший місяць користування авансом.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              4.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Розмір орендної плати може переглядатися Сторонами
                              не частіше ніж один раз протягом року або за
                              згодою сторін у разі:
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
              <ol style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  4.4.1.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Підвищення цін, тарифів, у тому числі
                                  внаслідок інфляції.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  4.4.2.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  Погіршення стану Об'єкту оренди не з вини
                                  Орендаря, що підтверджено документами.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              4.5.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Орендар має право вносити орендну плату наперед за
                              будь-який термін у розмірі‚ що визначається на
                              момент оплати. У цьому випадку порядок перегляду
                              орендної плати за п.4.5 Даного Договору не
                              застосовується на оплачений термін.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            5. ПОРЯДОК ПЕРЕДАЧІ ОБ'ЄКТА В ОРЕНДУ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginTop: "0.08in",
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              5.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Об’єкт оренди повинен бути переданий Орендодавцем
                              та прийнятий Орендарем протягом 3 (трьох) днів з
                              моменту отримання авансу від Орендаря.{" "}
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              5.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Передача Об'єкта оренди здійснюється відповідними
                              фахівцями сторін згідно Специфікації, яка є
                              невід’ємною частиною даного договору.{" "}
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              5.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Об’єкт оренди вважається переданим в оренду з
                              моменту підписання відповідної Специфікації.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            6. ПРАВА ТА ОБОВ'ЯЗКИ СТОРІН
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginTop: "0.08in",
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Обов'язки Орендаря:
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Використовувати Об'єкт оренди за його цільовим
                              призначенням у відповідності до п.2 цієї Угоди.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <span style={{ background: "transparent" }}>
                        <font>
                          <font size={2} style={{ fontSize: "11pt" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.3.
                            </span>
                            <span style={{ fontWeight: "normal" }}>
                              Своєчасно здійснювати орендні платежі.
                            </span>
                          </font>
                        </font>
                      </span>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Дотримуватися належного режиму експлуатації та
                              зберігання Об'єкту оренди у відповідності до
                              технічної документації.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.5.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Обов'язки Орендодавця:
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.6.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Ознайомити Орендаря з правилами технічної
                              експлуатації Об’єкта оренди‚ і, за необхідності,
                              направити свого фахівця для проведення
                              відповідного інструктажу на місці експлуатації.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span
                            style={{
                              width: "60px",
                              display: "inline-block",
                            }}
                          >
                            6.7.
                          </span>
                          <span style={{ fontWeight: "normal" }}>
                            <span style={{ background: "transparent" }}>
                              Надавати інформаційне та консультаційне сприяння
                              Орендареві щодо порядку правильної експлуатації
                              Об’єкта оренди.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.8.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Права Орендодавця:
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              6.9.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Здійснювати перевірку порядку використання
                              Орендарем Об'єкту оренди у відповідності до умов
                              цієї Угоди.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            7. СТРАХУВАННЯ ОБ'ЄКТА ОРЕНДИ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginTop: "0.08in",
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              7.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Ризик випадкового знищення або пошкодження Об’єкта
                              оренди чи його частини несе Орендар.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              7.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Згідно з цією Угодою Об’єкт оренди підлягає
                              страхуванню на користь Орендодавця.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              7.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Страхування об’єкта оренди здійснює Орендодавець.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              7.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Сторони домовилися про те, що у разі невиконання
                              свого обов’язку стороною,яка повинна згідно з цим
                              договором застрахувати Об’єкт оренди, інша сторона
                              може застрахувати його і вимагати відшкодування
                              витрат на страхування.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            8. ПОРЯДОК ПОВЕРНЕННЯ ОРЕНДОДАВЦЮ ОБ'ЄКТА ОРЕНДИ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginTop: "0.08in",
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              8.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Після закінчення терміну оренди Орендар
                              зобов’язаний протягом 5 (п’яти) календарних днів
                              повернути Об'єкт оренди Орендодавцю разом з тарою‚
                              упаковкою та технічною документацією (комплектно)
                              за власний рахунок.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              8.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Повернення Об'єкта оренди здійснюється
                              відповідними фахівцями сторін за актом.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              8.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Об'єкт оренди повинен бути переданий Орендарю у
                              справному стані з урахуванням нормального зносу.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              8.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Об'єкт оренди може бути викуплений Орендарем
                              (повністю або частково) в порядку та на умовах‚
                              погоджених окремо.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            ВІДПОВІДАЛЬНІСТЬ СТОРІН
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginTop: "0.08in",
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              9.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Орендар несе наступну відповідальність за цією
                              Угодою:
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
              <ol style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  9.1.1.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  У випадку прострочення по сплаті орендних
                                  платежів пеня в розмірі подвійної ставки НБУ
                                  від суми боргу за кожен день прострочення.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  9.1.2.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  У випадку нецільового використання Об'єкта
                                  оренди‚ штраф у розмірі 50% від суми
                                  щомісячної орендної плати за період
                                  нецільового використання.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  9.1.3.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  За передачу Об'єкта оренди або його частини в
                                  суборенду без згоди Орендодавця штраф у
                                  розмірі 50% від суми щомісячної орендної плати
                                  за період такої суборенди.
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              9.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Орендодавець несе наступну відповідальність за
                              цією Угодою:
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
              <ol style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <p
                    className="western"
                    align="justify"
                    style={{
                      pageBreakInside: "auto",
                      orphans: 2,
                      widows: 2,
                      marginBottom: "0in",
                      border: "none",
                      padding: "0in",
                      background: "transparent",
                      pageBreakBefore: "auto",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <span style={{ fontVariant: "normal" }}>
                      <font color="#fff">
                        <span style={{ textDecoration: "none" }}>
                          <font>
                            <font size={2} style={{ fontSize: "11pt" }}>
                              <span style={{ fontStyle: "normal" }}>
                                <span
                                  style={{
                                    width: "60px",
                                    display: "inline-block",
                                  }}
                                >
                                  9.2.1.
                                </span>
                                <span style={{ background: "transparent" }}>
                                  У випадку прострочення передачі Орендареві
                                  Об'єкта оренди‚ Орендодавець сплачує пеню в
                                  розмірі подвійної ставки НБУ від суми
                                  щомісячної орендної плати пропорційно за кожен
                                  день прострочення.{" "}
                                </span>
                              </span>
                            </font>
                          </font>
                        </span>
                      </font>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p
            className="western"
            align="left"
            style={{
              pageBreakInside: "auto",
              orphans: 2,
              widows: 2,
              border: "none",
              padding: "0in",
              background: "transparent",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <span style={{ fontVariant: "normal" }}>
              <font color="#fff">
                <span style={{ textDecoration: "none" }}>
                  <font>
                    <font size={2} style={{ fontSize: "11pt" }}>
                      <span style={{ fontStyle: "normal" }}>
                        <b>
                          <span style={{ background: "transparent" }}>
                            10. ІНШІ УМОВИ
                          </span>
                        </b>
                      </span>
                    </font>
                  </font>
                </span>
              </font>
            </span>
          </p>
          <ol style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginTop: "0.08in",
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.1.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Цей Договір набуває чинності з моменту її
                              підписання Сторонами і діє протягом 24 календарних
                              місяців або до моменту повного виконання Сторонами
                              своїх зобов’язань за цією Угодою.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.2.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Зміни до цієї Угоди вносяться шляхом опублікування
                              орендодавцем нової редакції Договору на сайті
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
                <font>offer.alarius.com.ua</font>
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span style={{ background: "transparent" }}>
                              . Орендодавець повідомляє Орендаря про нову
                              редакцію Угоди шляхом направлення відповідного
                              повідомлення через Програму під час або після
                              входу в Особистий кабінет. Орендар вважається
                              належним чином повідомленим про зміни в Угоді з
                              моменту відправлення йому відповідного
                              повідомлення
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.3.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Дострокове розірвання цієї Угоди можливе лише за
                              письмовою згодою Сторін,якщо інше не встановлено
                              цією Угодою або законодавством України.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.4.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Усі спори, що пов’язані з цією Угодою вирішуються
                              шляхом переговорів між Сторонами.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.5.
                            </span>
                            <span style={{ background: "transparent" }}>
                              У випадках, не передбачених цією Угодою, сторони
                              керуються нормами чинного законодавства.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
            <li>
              <p
                className="western"
                align="justify"
                style={{
                  pageBreakInside: "auto",
                  orphans: 2,
                  widows: 2,
                  marginBottom: "0in",
                  border: "none",
                  padding: "0in",
                  background: "transparent",
                  pageBreakBefore: "auto",
                  pageBreakAfter: "auto",
                }}
              >
                <span style={{ fontVariant: "normal" }}>
                  <font color="#fff">
                    <span style={{ textDecoration: "none" }}>
                      <font>
                        <font size={2} style={{ fontSize: "11pt" }}>
                          <span style={{ fontStyle: "normal" }}>
                            <span
                              style={{
                                width: "60px",
                                display: "inline-block",
                              }}
                            >
                              10.6.
                            </span>
                            <span style={{ background: "transparent" }}>
                              Орендодавець має статус платника податку єдиного
                              податку.
                            </span>
                          </span>
                        </font>
                      </font>
                    </span>
                  </font>
                </span>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p
        className="western"
        align="justify"
        style={{
          fontVariant: "normal",
          fontStyle: "normal",
          fontWeight: "normal",

          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          textDecoration: "none",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <br />
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>
                        ОРЕНДОДАВЕЦЬ
                      </span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>
                        Товариство з обмеженою відповідальністю "АЛАРІУС ФМС"
                      </span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>
                        Код ЄДРПОУ:
                      </span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        43572863
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>ІПН</span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        : 265020079987
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>
                        Місцезнаходження:
                      </span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        04119, місто Київ, вул. Дегтярівська, будинок 25/1,
                        поверх 3
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>Р/р:</span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        UA333052990000026008006815625,
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>Банк</span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        : ПАТ «ПРИВАТБАНК», м. Київ,
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>МФО</span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        : 300711{" "}
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        align="justify"
        style={{
          fontVariant: "normal",
          fontStyle: "normal",
          fontWeight: "normal",

          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          textDecoration: "none",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <br />
      </p>
      <p
        className="western"
        align="justify"
        style={{
          pageBreakInside: "auto",
          orphans: 2,
          widows: 2,
          marginBottom: "0in",
          border: "none",
          padding: "0in",
          background: "transparent",
          pageBreakBefore: "auto",
          pageBreakAfter: "auto",
        }}
      >
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <b>
                      <span style={{ background: "transparent" }}>
                        Директор
                      </span>
                    </b>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
        <span style={{ fontVariant: "normal" }}>
          <font color="#fff">
            <span style={{ textDecoration: "none" }}>
              <font>
                <font size={2} style={{ fontSize: "11pt" }}>
                  <span style={{ fontStyle: "normal" }}>
                    <span style={{ fontWeight: "normal" }}>
                      <span style={{ background: "transparent" }}>
                        {" "}
                        Тарас НІКОЛЕНКО
                      </span>
                    </span>
                  </span>
                </font>
              </font>
            </span>
          </font>
        </span>
      </p>
      <p
        className="western"
        style={{ lineHeight: "108%", marginBottom: "0.11in" }}
      >
        <br />
        <br />
      </p>
    </div>
  );
};

export default AdditionalAgreementTemplate;
