import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Мінімальна довжина 2 символи")
    .max(255, "Максимальна довжина 120 символів")
    .required("Заповнінть поле")
    .nullable(true),
  phone: Yup.string().test(
    "phone",
    "Введіть коректний номер телефону",
    (value) => {
      return /^(?:\+38)? (\(0\d{2}\) \d{3} \d{2} \d{2})$/u.test(value || "");
    }
  ),
  email: Yup.string()
    .required("Заповнінть поле")
    .email("Введіть коректний email")
    .nullable(true),
  description: Yup.string()
    .min(10, "Мінімальна довжина 10 символів")
    .max(500, "Максимальна довжина 500 символів")
    .required("Заповнінть поле")
    .nullable(true),
});
