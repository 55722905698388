import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { SxPropsObject } from "../../../types";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/download.svg";

type PropsType = {
  title: string;
  subTitle?: string;
  file?: any;
  Template?: any;
  id: string;
  isOpened: boolean;
};

const File: React.FC<PropsType> = (props) => {
  const { title, subTitle, file, Template, id, isOpened } = props;
  const [isDocumentOpened, setIsDocumentOpened] = useState(isOpened);

  const toggleDocument = async () => {
    setIsDocumentOpened((val) => !val);
  };

  return (
    <Box mt={10} id={id}>
      <Typography variant="h3">{title}</Typography>
      {subTitle && (
        <Typography mt={1} sx={styles.subTitle}>
          {subTitle}
        </Typography>
      )}
      <Box sx={styles.container}>
        {!isDocumentOpened && (
          <Typography sx={styles.link} onClick={toggleDocument}>
            Показати текст документу
          </Typography>
        )}
        {isDocumentOpened && (
          <Typography sx={styles.link} onClick={toggleDocument}>
            Приховати текст документу
          </Typography>
        )}
        {file && (
          <Box sx={styles.download}>
            <Typography
              component="a"
              sx={styles.link}
              ml={1}
              href={file}
              download
            >
              <DownloadIcon style={{ marginRight: 8 }} />
              Завантажити PDF
            </Typography>
          </Box>
        )}
      </Box>
      {isDocumentOpened && (
        <div style={{ paddingTop: "20px" }}>
          <Template />
        </div>
      )}
    </Box>
  );
};

const styles: SxPropsObject = {
  container: {
    display: "flex",
    mt: 1,
  },
  subTitle: {
    fontSize: "1.25rem",
  },
  link: {
    fontSize: "16px",
    color: "#00A1FF",
    cursor: "pointer",
    textDecoration: "none",
  },
  download: {
    display: "flex",
    alignItems: "center",
    ml: 5,
  },
};

export default File;
