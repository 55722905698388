import { Box, Typography } from "@mui/material";
import { SxPropsObject } from "../../types";

const NotFound = () => {
  return (
    <Box sx={styles.content}>
      <Typography variant="h2" color="text.primary" textAlign="center">
        Сторінку не знайдено
      </Typography>
    </Box>
  );
};

const styles: SxPropsObject = {
  content: (theme) => ({
    pt: "175px",
    px: "24px",
    [theme.breakpoints.down(1200)]: {
      pt: "145px",
      px: "0px",
    },
    [theme.breakpoints.down("md")]: {
      pt: "100px",
      px: "0px",
    },
  }),
};

export default NotFound;
