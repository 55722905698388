import {styled} from "@mui/material/styles";
import Box, {BoxProps} from "@mui/material/Box";

interface ContainerBoxProps extends BoxProps {
  visible: boolean;
}

export const ContainerBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'visible',
})<ContainerBoxProps>(({ theme, visible }) => ({
  padding: theme.spacing(5),
  background: 'linear-gradient(0deg, #FF5959, #FF5959), #211F2A',
  borderRadius: theme.spacing(2),
  display: 'inline-flex',
  flexDirection: 'column',
  position: 'absolute',
  transform: 'translate(0, -50%)',
  opacity: visible ? 1 : 0,
  transition: 'opacity 0.2s linear',

  [theme.breakpoints.down(1200)]: {
    padding: theme.spacing(2),
  }
}));
