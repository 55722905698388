import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import bg from '../../../assets/svg/usageBg.svg';

export const UsageBox = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  display: 'flex',
  position: 'relative',
  background: theme.palette.primary.main,

  '&::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    pointerEvents: 'none',
    zIndex: 1,
  }
}));

export const UsageLine = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  width: '33.3333%',
  left: '18%',
  transform: 'translate(-20%, 10%)',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  }
}));
