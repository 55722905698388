import { styled, css } from "@mui/material/styles";
import startIcon from '../../../../assets/svg/start.svg';
import Box, {BoxProps} from "@mui/material/Box";

interface TimeLineItemBoxElementProps extends BoxProps {
  first: boolean;
  reverse: boolean;
}

export const TimeLineItemBoxElement = styled(Box)<TimeLineItemBoxElementProps>`
  position: absolute;
  left: 0;
  height: ${({theme, first}) => {
    return first ? theme.spacing(10) : theme.spacing(5);
  }};
  width: 1px;
  background: ${({theme}) => theme.palette.secondary.main};
  z-index: 2;

  ${({theme}) => theme.breakpoints.down('md')} {
    display: none;
  }
  
  ${({reverse}) => css({
    transform: reverse ? 'translate(0, -18px) rotate(180deg)' : 'translate(0, 18px)',
    top: reverse ? 0 : 'auto',
    bottom: reverse ? 'auto' : 0,
  })}

  ${({theme, first}) => first && css({
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '100%',
      left: 0,
      width: theme.spacing(8),
      height: theme.spacing(3.5),
      background: `url(${startIcon}) no-repeat center`,
      backgroundSize: 'contain',
    }
  })}
  
  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({theme}) => theme.spacing(1)};
    height: ${({theme}) => theme.spacing(1)};
    border-radius: 50%;
    background: ${({theme}) => theme.palette.secondary.main};

    ${({theme}) => theme.breakpoints.down('md')} {
      display: none;
    }
  }
`;

interface TimeLineItemBoxProps extends BoxProps {
  reverse: boolean;
}

export const TimeLineItemBox = styled(Box)<TimeLineItemBoxProps>`
  position: relative;
  padding: ${({theme}) => `0 ${theme.spacing(3)}`};
  display: flex;
  flex-direction: ${
    ({reverse}) => reverse ? 'column-reverse': 'column'
  };
  ${({theme}) => theme.breakpoints.down(1200)} {
    padding: ${({theme}) => `0 ${theme.spacing(2)}`};
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    padding: 0;
  }
`;
